import React from 'react';
import CarrouselSection from '../CarrouselSection';
import { Link } from 'react-router-dom';

const BestSellerProducts = ({ slides }) => {
  return (
    <section className="space-y-2 text-black text-start py-14 md:py-24 h2">
      <div>
        <div className="absolute px-4 lg:px-20">
          <div className="flex items-center justify-between gap-4">
            <h2 className='lowercase'>
              Los más<span className="text-primary"> buscados</span>
            </h2>
          </div>
        </div>
        {/* Carousel */}
        <CarrouselSection slides={slides} slidesPerView={4} class_mobile="products_carrousel_mobile" />
      </div>
      {/* <div className="flex justify-center pt-9">
        <Link to="/alquiler">
          <button className="flex items-center justify-center py-6 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
            <p>Ver todos</p>
          </button>
        </Link>
      </div> */}
    </section>
  );
};

export default BestSellerProducts;
