import React, { useState, useEffect } from "react";

import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/Suppliers/hero-suppliers.webp";
import IconSuppliers1 from "../../src/assets/images/Suppliers/icon-suppliers-1.svg";
import IconSuppliers2 from "../../src/assets/images/Suppliers/icon-suppliers-2.svg";
import IconSuppliers3 from "../../src/assets/images/Suppliers/icon-suppliers-3.svg";
import IconSuppliers4 from "../../src/assets/images/Suppliers/icon-suppliers-4.svg";
import IconSuppliers5 from "../../src/assets/images/Suppliers/icon-suppliers-5.svg";
import IconSuppliers6 from "../../src/assets/images/Suppliers/icon-suppliers-6.svg";
import IconStep1 from "../../src/assets/images/Suppliers/icon-step1.svg";
import IconStep2 from "../../src/assets/images/Suppliers/icon-step2.svg";
import IconStep3 from "../../src/assets/images/Suppliers/icon-step3.svg";
import ElGestionadorImage from "../../src/assets/images/Suppliers/elgestionador.png";
import { HighlightedTitle } from "../components/ui/HighlightedTitle";
import Button from "../components/ui/Button";

const ImageWithTextVariables = {
  title: "únete a nuestra comunidad de alquiladores",
  highlightedText: "comunidad de alquiladores",
  highlightClass: "text-primary block",
  subtitle:
    "multitud de empresas alquiladoras de maquinaria y herramientas han encontrado en elalquilador su socio online",
  buttonText: "Empieza hoy mismo",
  href: "#section1",
  image: ImageWithTextImage,
  alt: "Sobre elalquilador",
  h1AdditionalClasses: "lg:!px-32",
};
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Suppliers() {
  return (
    <>
      <ImageWithText variables={ImageWithTextVariables} />
      <section>
        <div className="text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
          <h1 className="h1 lowercase pb-12">
            <span className="text-primary">Ventajas </span>
            para colaboradores
          </h1>
          <div className="w-full flex justify-center">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              <div className="flex items-center gap-4">
                <img
                  src={IconSuppliers1}
                  className="w-12 h-auto object-cover"
                  alt="Visibilidad y digitalización"
                />
                <p className="body font-bold text-start">
                  Visibilidad y digitalización
                </p>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={IconSuppliers2}
                  className="w-12 h-auto object-cover"
                  alt="Incremento de alquileres"
                />
                <p className="body font-bold text-start">
                  Incremento de alquileres
                </p>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={IconSuppliers3}
                  className="w-12 h-auto object-cover"
                  alt="Confianza y seguridad"
                />
                <p className="body font-bold text-start">
                  Confianza y seguridad
                </p>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={IconSuppliers4}
                  className="w-12 h-auto object-cover"
                  alt="Gestión y optimización de stock"
                />
                <p className="body font-bold text-start">
                  Gestión y optimización de stock
                </p>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={IconSuppliers5}
                  className="w-12 h-auto object-cover"
                  alt="Sostenibilidad certificada"
                />
                <p className="body font-bold text-start">
                  Sostenibilidad certificada
                </p>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={IconSuppliers6}
                  className="w-12 h-auto object-cover"
                  alt="Sin tasas ni cuotas"
                />
                <p className="body font-bold text-start">Sin tasas ni cuotas</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" className="mb-12">
        <div className="container m-auto text-center px-4 lg:px-12 py-14 md:py-24 text-black h2 space-y-1">
          <h2 className="pb-2 h1 lowercase">
            ¿Eres una
            <span className="text-primary"> empresa de alquiler </span>
            de maquinaria?
          </h2>
          <h2 className="pb-12 h1 lowercase">
            ¿Te gustaría ampliar tu
            <span className="text-primary"> negocio digital </span>
            sin costes extra?
          </h2>
          {/* Step 1 */}
          <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-12">
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconStep1}
                  className="w-32 h-auto object-cover"
                  alt="Solicitud de registro"
                />
              </div>
              <p className="h4 text-center">
                Haz tu solicitud de registro completando los campos
              </p>
            </div>
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconStep2}
                  className="w-32 h-auto object-cover"
                  alt="Añade tus productos"
                />
              </div>
              <p className="h4 text-center">
                Añade tus productos al finalizar el registro
              </p>
            </div>
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconStep3}
                  className="w-32 h-auto object-cover"
                  alt="Amplia tu negocio"
                />
              </div>
              <p className="h4 text-center">
                ¡Amplia tu negocio y maximiza beneficios!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="section1">
        <RegistrationForm />
      </section>
      <section className="bg-white rounded-3xl px-4 lg:px-20 py-14 md:py-24 container m-auto mb-12">
        <h1 className="text-dark headline text-center pb-9 relative lowercase">
          Presentamos <span className="text-[#008EA3]">elgestionador</span>
          <div className="absolute -top-6 right-[20vh] bg-[#008EA3] rounded-full py-1 px-4 text-white body flex justify-center items-center">
            nuevo
          </div>
        </h1>
        <h2 className="text-dark h3 text-center lowercase">
          ¿Quieres optimizar la operativa de tu negocio?
        </h2>
        <p className="text-dark body text-center pt-12 px-0 lg:px-32">
          Con elgestionador accede a los datos de tu empresa desde cualquier
          lugar. Te facilitamos la administración de inventario y de reservas,
          con la información de stock en tiempo real, el cobro y la facturación
          electrónica.
        </p>
        <div className="flex justify-center pt-2 lg:pt-9">
            <Button href="https://www.elgestionador.com/"  type="primary" classButton='lowercase'>
              Ir al gestionador
            </Button>
          </div>
        <img
          src={ElGestionadorImage}
          className="w-[80%] mt-20 mx-auto h-auto"
          alt="elgestionador"
        />
      </section>
    </>
  );
}

function RegistrationForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    surname: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    name: "",
    surname: "",
    server: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  // email format and required fields validation
  const validateForm = () => {
    const isValidEmail = /\S+@\S+\.\S+/.test(formData.email);
    const emailField = formData.email !== "";
    const nameFilled = formData.name !== "";
    const surnameFilled = formData.surname !== "";

    return {
      email: !emailField ? "Email requerido" : !isValidEmail ? "El correo introducido no es válido" : "",
      name: nameFilled ? "" : "Nombre requerido",
      surname: surnameFilled ? "" : "Apellido requerido",
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const currentErrors = validateForm();

    if (currentErrors.email || currentErrors.name || currentErrors.surname) {
      setErrors(currentErrors);
      setIsSubmitting(false);
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}/supplier-register`,
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setSuccessMessage(
            "Tus datos se han enviado correctamente, nos pondremos en contacto contigo."
          );
          setFormData({ email: "", name: "", surname: "", message: "" });
          setErrors({ email: "", name: "", surname: "", server: "" });
        } else {
          throw new Error(
            data.message || "Ha habido un error, intentálo otra vez."
          );
        }
      } catch (error) {
        setErrors((prevErrors) => ({ ...prevErrors, server: error.message }));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // update formData and errors states
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors.server || successMessage) {
      setErrors((prevErrors) => ({ ...prevErrors, server: "" }));
      setSuccessMessage("");
    }

    if (errors[name] && value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };
  return (
    <section id="form" className="px-4 lg:px-20 py-14 md:py-24">
      <div className="bg-white mb-12 container m-auto rounded-3xl px-4 py-12 lg:p-12">
        <h2 className="h1 lowercase flex justify-center pb-12">
          <HighlightedTitle
            title="Regístrate y únete a nuestra comunidad"
            highlight="Regístrate"
            highlightClass="text-primary pr-2"
          />
        </h2>
        <form className="lg:px-32" onSubmit={handleSubmit}>
          <div className="mb-2">
            <input
              className={`w-full mb-2 appearance-none rounded-full border-2 ${
                errors.email ? "border-red-500" : "border-dark"
              } bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0`}
              id="email"
              type="email"
              name="email"
              placeholder="Correo electrónico*"
              value={formData.email}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {errors.email && (
              <p className="text-red-500 text-xs text-right">{errors.email}</p>
            )}
          </div>
          <div className="mb-4 lg:flex gap-6">
            <input
              className={`mb-2 lg:mb-0 w-full md:w-1/2 appearance-none rounded-full border-2 ${
                errors.name ? "border-red-500" : "border-dark"
              } bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0`}
              id="name"
              type="text"
              name="name"
              placeholder="Nombre*"
              value={formData.name}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <input
              className={`w-full md:w-1/2 appearance-none rounded-full border-2 ${
                errors.surname ? "border-red-500" : "border-dark"
              } bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0`}
              id="surname"
              type="text"
              name="surname"
              placeholder="Apellidos*"
              value={formData.surname}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </div>
          {(errors.name || errors.surname) && (
            <div className="mb-4 lg:flex gap-6">
              <p className="w-1/2 text-red-500 text-xs text-right">{errors.name}</p>
              <p className="w-1/2 text-red-500 text-xs text-right">{errors.surname}</p>
            </div>
          )}
          <div className="mb-4 lg:flex gap-6">
            <textarea
              className="resize-both w-full h-[20vh] rounded-2xl border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
              id="message"
              name="message"
              placeholder="Introduzca su mensaje"
              value={formData.message}
              onChange={handleChange}
              disabled={isSubmitting}
            ></textarea>
          </div>
          <div className="flex justify-center pt-2 lg:pt-9">
            <Button type="primaryBlack" disabled={isSubmitting}>
              Empieza hoy mismo
            </Button>
          </div>

          {successMessage && (
            <div className="mt-6 text-primary">{successMessage}</div>
          )}
          {errors.server && (
            <div className="mt-6 text-red-500">{errors.server}</div>
          )}
        </form>
      </div>
    </section>
  );
}

// kept if they change it again
// function JoinForm() {
//   const [formData, setFormData] = useState({
//     email: "",
//     name: "",
//     surname: "",
//     company: "",
//     cif: "",
//     phone: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log(formData);
//   };

//   return (
//     <section id="form" className="px-4 lg:px-20 py-14 md:py-24">
//       <div className="bg-white mb-12 container m-auto rounded-3xl px-4 py-12 lg:p-12">
//         <h2 className="h1 lowercase flex justify-center pb-12">
//           <span className="text-primary pr-2">Únete </span>a la comunidad
//         </h2>
//         <form className="lg:px-32" onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <input
//               className="w-full appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
//               id="email"
//               type="email"
//               name="email"
//               placeholder="Correo electrónico*"
//               value={formData.email}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-4 lg:flex gap-6">
//             <input
//               className="mb-4 lg:mb-0 w-full md:w-1/2 appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
//               id="name"
//               type="text"
//               name="name"
//               placeholder="Nombre*"
//               value={formData.name}
//               onChange={handleChange}
//             />
//             <input
//               className="w-full md:w-1/2 appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
//               id="surname"
//               type="text"
//               name="surname"
//               placeholder="Apellidos*"
//               value={formData.surname}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-4">
//             <input
//               className="w-full appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
//               id="company"
//               type="text"
//               name="company"
//               placeholder="Razón Social*"
//               value={formData.company}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-4 lg:flex gap-6">
//             <input
//               className="mb-4 lg:mb-0 w-full md:w-1/2 appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
//               id="cif"
//               type="text"
//               name="cif"
//               placeholder="CIF*"
//               value={formData.cif}
//               onChange={handleChange}
//             />
//             <input
//               className="w-full md:w-1/2 appearance-none rounded-full border-2 border-dark bg-transparent px-4 py-3 text-sm font-bold text-primary ring-0 placeholder:font-normal placeholder:text-zinc-400 focus:border-primary focus:outline-none focus:ring-0"
//               id="phone"
//               type="tel"
//               name="phone"
//               placeholder="Teléfono*"
//               value={formData.phone}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="flex justify-center pt-2 lg:pt-9">
//             <button
//               type="submit"
//               className="bg-primary flex items-center justify-center text-white rounded-full px-14 py-6 border-2 border-primary hover:bg-transparent hover:border-primary hover:text-dark transition-all ease-in-out duration-150"
//             >
//               Empieza hoy mismo gratis
//             </button>
//           </div>
//           <div className="w-full px-12 pt-4 text-center text-sm text-gray-300 mt-12">
//             <p>
//               Al continuar confirmas que aceptas nuestros
//               <a href="#section1" target="_blank" className="underline">
//                 {" "}
//                 Términos y condiciones,
//               </a>
//               <a href="#section1" target="_blank" className="underline">
//                 {" "}
//                 Política de Privacidad
//               </a>{" "}
//               y
//               <a href="#section1" target="_blank" className="underline">
//                 {" "}
//                 Cookies
//               </a>
//               .
//             </p>
//           </div>
//         </form>
//       </div>
//     </section>
//   );
// }

// function FaqSection() {
//   const [isOpen, setIsOpen] = useState({
//     item1: false,
//     item2: false,
//     item3: false,
//   });

//   const toggleItem = (item) => {
//     setIsOpen({ ...isOpen, [item]: !isOpen[item] });
//   };

//   return (
//     <section className="px-4 lg:px-20 py-14 md:py-24">
//       <div className="container m-auto text-center text-black h2 space-y-1">
//         <h2 className="h1 lowercase">
//           <span className="text-primary">Resolvemos</span> tus dudas
//         </h2>
//       </div>
//       <div className="accordion w-full lg:w-[60vw] mt-12 m-auto">
//         {/* FAQ 1 */}
//         <div
//           id="function1"
//           className="border-b border-black border-opacity-20 py-2"
//         >
//           <div
//             className="cursor-pointer accordion-label block my-4"
//             onClick={() => toggleItem("item1")}
//           >
//             <div className="flex gap-9 items-center justify-between">
//               <h4 className="h4 font-bold text-black select-none">
//                 ¿Debo registrarme en elalquilador.com para alquilar una máquina
//                 o herramienta profesional?
//               </h4>
//               <div>
//                 {/* Toggle Icon */}
//                 <div
//                   className={`${
//                     isOpen.item1 ? "hidden" : ""
//                   } open-icon bg-light h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center text-center items-center text-primary hover:bg-primary hover:text-white transition-all ease-in-out duration-150`}
//                 >
//                   <span className="h2">+</span>
//                 </div>
//                 <div
//                   className={`${
//                     isOpen.item1 ? "" : "hidden"
//                   } close-icon bg-red-400 h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center text-center items-center text-white hover:bg-dark hover:text-white transition-all ease-in-out duration-150`}
//                 >
//                   <span className="h2">-</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Answer 1 */}
//           <div
//             className={`accordion-content overflow-hidden transition-max-height duration-300 ${
//               isOpen.item1 ? "max-h-screen" : "max-h-0"
//             } text-start`}
//           >
//             <div className="body font-bold text-primary pb-4 leading-5">
//               <p>
//                 Sí, en elaquilador.com, valoramos la seguridad y eficiencia de
//                 nuestros clientes.
//               </p>
//               <p className="pt-3 text-dark">
//                 El registro en elalquilador.com es un paso esencial para
//                 garantizar un proceso de alquiler profesional y sin
//                 complicaciones. Al registrarte, obtendrás acceso a exclusivos
//                 beneficios, como un perfil personalizado, un historial de
//                 alquiler detallado y la posibilidad de realizar un seguimiento
//                 de tus transacciones. Además, la información proporcionada
//                 durante el registro nos permite ofrecerte un servicio
//                 individualizado y una experiencia de alquiler más eficiente.
//               </p>
//             </div>
//           </div>
//         </div>
//         {/* FAQ 2 */}
//         <div
//           id="function2"
//           className="border-b border-black border-opacity-20 py-2"
//         >
//           <div
//             className="cursor-pointer accordion-label block my-4"
//             onClick={() => toggleItem("item2")}
//           >
//             <div className="flex gap-9 items-center justify-between">
//               <h4 className="h4 font-bold text-black select-none">
//                 ¿Qué incluye el precio cuando alquilas máquinas o herramientas
//                 profesionales?
//               </h4>
//               <div>
//                 {/* Toggle Icon */}
//                 <div
//                   className={`${
//                     isOpen.item2 ? "hidden" : ""
//                   } open-icon bg-light h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center text-center items-center text-primary hover:bg-primary hover:text-white transition-all ease-in-out duration-150`}
//                 >
//                   <span className="h2">+</span>
//                 </div>
//                 <div
//                   className={`${
//                     isOpen.item2 ? "" : "hidden"
//                   } close-icon bg-red-400 h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center text-center items-center text-white hover:bg-dark hover:text-white transition-all ease-in-out duration-150`}
//                 >
//                   <span className="h2">-</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Answer 2 */}
//           <div
//             className={`accordion-content overflow-hidden transition-max-height duration-300 ${
//               isOpen.item2 ? "max-h-screen" : "max-h-0"
//             } text-start`}
//           >
//             <div className="body font-bold text-primary pb-4 leading-5">
//               {/* Content for Answer 2 */}
//               <p className="pb-3">1.Precio por día.</p>
//               <p className="pb-3">
//                 2.Fianza:{" "}
//                 <span className="text-dark">
//                   Una fianza que se requiere al momento de la reserva. Esta
//                   fianza se utiliza como garantía y se reembolsará una vez que
//                   se devuelva la maquinaria o herramienta en las mismas
//                   condiciones en que se entregaron.
//                 </span>
//               </p>
//               <p className="pb-3">
//                 3.Transporte:{" "}
//                 <span className="text-dark">
//                   Dependiendo de la ubicación y las necesidades de entrega, el
//                   precio de alquiler puede incluir el transporte de la
//                   maquinaria o herramienta a tu lugar de trabajo.
//                 </span>
//               </p>
//               <p className="pb-3">
//                 4.
//                 <span className="text-dark">
//                   En algunos casos existirá la opción de contratar un operario o
//                   el montaje.
//                 </span>
//               </p>
//               <p className="pb-3">
//                 5.
//                 <span className="text-dark">
//                   Otros gastos relacionados con la maquinaria: Seguro de
//                   responsabilidad civil y gestión de residuos obligatorios para
//                   garantizar el cumplimiento de las regulaciones y prácticas
//                   ambientales.
//                 </span>
//               </p>
//             </div>
//           </div>
//         </div>
//         {/* FAQ 3 */}
//         <div
//           id="function3"
//           className="border-b border-black border-opacity-20 py-2"
//         >
//           <div
//             className="cursor-pointer accordion-label block my-4"
//             onClick={() => toggleItem("item3")}
//           >
//             <div className="flex gap-9 items-center justify-between">
//               <h4 className="h4 font-bold text-black select-none">
//                 ¿Quién se puede beneficiar del alquiler de maquinarias y
//                 herramientas profesionales?
//               </h4>
//               <div>
//                 {/* Toggle Icon */}
//                 <div
//                   className={`${
//                     isOpen.item3 ? "hidden" : ""
//                   } open-icon bg-light h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center text-center items-center text-primary hover:bg-primary hover:text-white transition-all ease-in-out duration-150`}
//                 >
//                   <span className="h2">+</span>
//                 </div>
//                 <div
//                   className={`${
//                     isOpen.item3 ? "" : "hidden"
//                   } close-icon bg-red-400 h-9 md:h-12 w-9 md:w-12 rounded-full flex justify-center text-center items-center text-white hover:bg-dark hover:text-white transition-all ease-in-out duration-150`}
//                 >
//                   <span className="h2">-</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Answer 3 */}
//           <div
//             className={`accordion-content overflow-hidden transition-max-height duration-300 ${
//               isOpen.item3 ? "max-h-screen" : "max-h-0"
//             } text-start`}
//           >
//             <div className="body font-bold text-primary pb-4 leading-5">
//               {/* Content for Answer 3 */}
//               <p className="pb-3">
//                 La buena noticia para nuestros usuarios es que todos se pueden
//                 beneficiar del alquiler de la maquinaria y herramientas: tanto
//                 particulares como autónomos, empresas e instituciones públicas.
//                 Los casos son muy variados.
//               </p>
//               <p className="pb-3 text-dark">
//                 Por ejemplo, los usuarios particulares ven en nosotros una
//                 magnífica oportunidad de conseguir un equipo de alta calidad, en
//                 perfecto estado de mantenimiento, pero a un precio
//                 insignificante comparado con la compra. También es muy útil para
//                 comunidades de vecinos que desean disminuir los gastos en tareas
//                 de mantenimiento o en reparación de desperfectos. Y lo mismo
//                 ocurre con ayuntamientos y entes públicos que quieren contar con
//                 herramienta de primera sin trastocar las partidas
//                 presupuestarias.
//               </p>
//               <p className="pb-3 text-dark">
//                 Los autónomos y las pequeñas empresas, por su parte, pueden
//                 recurrir a este servicio para reforzar la operativa en picos de
//                 demanda o bien para sustituir equipos cuando estos se han
//                 averiado inesperadamente. Y si acabas de montar tu negocio y no
//                 puedes aún invertir en nueva herramienta, ¿qué mejor que usarla
//                 en régimen de alquiler y repercutir los gastos en la factura?.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

export default Suppliers;
