import React, { useContext, useRef, useState, useEffect } from 'react';
import MultiRangeSlider from './ui/MultiRangeSlider';
import { useFilterContext } from '../helpers/FilterContext';

function FilterItem({ filter, filtersContent }) {
  const { dispatch } = useFilterContext(); // Importa dispatch del contexto de filtro
  const filterName = filter[0]
  let rangeMinMax = [];
  let rangeMinMaxMultiple = [];
  let singleRange = 0
  if (
    filtersContent &&
    filtersContent.ranges &&
    filtersContent.ranges[filterName]
  ) {
    for (let key in filtersContent.ranges[filterName]) {
      const values = filtersContent.ranges[filterName][key];
      const min = parseFloat(values[0]);
      const max = parseFloat(values[1]);
      const measure = values[2];
  
      if (key === filterName) {
        rangeMinMax = [min, max, measure];
      } else {
        rangeMinMaxMultiple.push([min, max, measure, key]);
      }
      
    }
  }

  // Almacenar los valores iniciales del rango
  const initialRangeValues = useRef(rangeMinMax);
  const initialRangeValuesMultiple = useRef(rangeMinMaxMultiple);

  // Almacenar el identificador del temporizador
  const timerId = useRef(null);

  const handleRangeChange = (min, max, range = []) => {
    if(range){
    }
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    // Establecer un nuevo temporizador
    timerId.current = setTimeout(() => {
      // Comprobar si los valores actuales son diferentes a los iniciales
      if (range.length === 0) {
        if (min !== initialRangeValues.current[0] || max !== initialRangeValues.current[1]) {
          dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filter[0], values: [filterName, min, max] } });
        }
      } else {
        const index = rangeMinMaxMultiple.findIndex(r => r[0] === range[0] && r[1] === range[1]);
        if (index !== -1 && (min !== initialRangeValuesMultiple.current[index][0] || max !== initialRangeValuesMultiple.current[index][1])) {
          dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filter[0], values: [range[3], min, max] } });
        }
      }
    }, 1000); // Retrasar la ejecución en 1 segundo
  }

  return (
      <div className="flex flex-col gap-16 items-center py-4">
        {
          (!singleRange && rangeMinMax.length > 0) ? 
            <div className='w-full'>
              <h5>{rangeMinMax[2]}</h5>
              <MultiRangeSlider
                min={rangeMinMax[0]}
                max={rangeMinMax[1]}
                onChange={({ min, max }) => {
                  handleRangeChange(min, max);
                }}
              />
            </div>
          : rangeMinMaxMultiple.map((range, i) => (
            range.length > 0 ? 
            <div className='w-full' key={i}>
              <h5>{range[2]}</h5>
              <MultiRangeSlider
                min={range[0]}
                max={range[1]}
                onChange={({ min, max }) => {
                  handleRangeChange(min, max, range)
                }}
              />
            </div> : null
          ))
        }
      </div>
  );
}

export default FilterItem;