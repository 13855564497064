import { createContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DataEncoder } from "../utils/common";

export const PostsContext = createContext();

export const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [postsNoPagination, setPostsNoPagination] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const initialRender = useRef(true);
  const location = useLocation();
  const encoder = new DataEncoder();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [blogType, setBlogType] = useState("");

  const getTypeFromLocation = (locationPath) => {
    if (locationPath.includes("/blog")) {
      setBlogType("blog");
      return "blog";
    } else if (locationPath.includes("/guia")) {
      setBlogType("guia");
      return "guide";
    }
  };

  const fetchPosts = async (page = currentPage, appliedFilters = {}) => {
    setLoading(true);
    setError(null);

    const type = getTypeFromLocation(location.pathname);

    const arrayFilters = {
      filters: {
        domain: "alquilador",
        type,
        ...appliedFilters,
      },
      order: { created_at: "desc" },
      with: ["media", "category", "state"],
      page,
      per_page: 20,
    };

    const queryParams = encoder.encode(arrayFilters);
    const url = `${API_BASE_URL}/posts?${queryParams}`;

    try {
      const response = await fetch(encodeURI(url));
      if (!response.ok) {
        throw new Error("Error fetching posts");
      }

      const data = await response.json();
      const postsArray = Object.values(data.data);

      setPosts(
        postsArray.map((post) => ({
          id: post.id,
          slug: post.slug,
          title: post.title,
          ...post,
        }))
      );

      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
      setFrom(data.from);
      setTo(data.to);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPostsNoPagination = async (appliedFilters = {}) => {
    setLoading(true);
    setError(null);

    const type = getTypeFromLocation(location.pathname);

    const arrayFilters = {
      filters: {
        domain: "alquilador",
        type,
        ...appliedFilters,
      },
      order: { created_at: "desc" },
      with: ["media", "category", "state"],
      exclude: ["content"],
    };

    const queryParams = encoder.encode(arrayFilters);
    const url = `${API_BASE_URL}/posts?${queryParams}`;

    try {
      const response = await fetch(encodeURI(url));
      if (!response.ok) {
        throw new Error("Error fetching posts");
      }

      const data = await response.json();
      const postsArray = Object.values(data.data);

      setPostsNoPagination(
        postsArray.map((post) => ({
          id: post.id,
          slug: post.slug,
          title: post.title,
          ...post,
        }))
      );
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
    fetchPosts(1, newFilters);
    fetchPostsNoPagination(newFilters);
  };

  useEffect(() => {
    if (
      location.pathname.includes("/blog") ||
      location.pathname.includes("/guia")
    ) {
      if (initialRender.current) {
        fetchPosts(currentPage, filters);
        fetchPostsNoPagination(filters);
        initialRender.current = false;
      } else {
        fetchPosts(currentPage, filters);
        fetchPostsNoPagination(filters);
      }
    }
  }, [currentPage, filters, location.pathname]);

  return (
    <PostsContext.Provider
      value={{
        posts,
        postsNoPagination,
        filters,
        currentPage,
        totalPages,
        from,
        to,
        total,
        loading,
        error,
        fetchPosts,
        updateFilters,
        setCurrentPage,
        blogType,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};
