import React, { useRef, useEffect } from "react";

import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/Sustainability/hero-sustainability.webp";
import SustainabilityIntro1 from "../../src/assets/images/Sustainability/sustainability-intro_1.webp";
import SustainabilityIntro2 from "../../src/assets/images/Sustainability/sustainability-intro_2.webp";
import SustainabilityIntro3 from "../../src/assets/images/Sustainability/sustainability-intro_3.webp";
import SustainabilityIntro4 from "../../src/assets/images/Sustainability/sustainability-intro_4.webp";

import videoSource from "../../src/assets/images/Sustainability/sustainability-bg.mp4";
import logoSource from "../../src/assets/logos/light-logo.svg";
import backgroundImage from "../../src/assets/images/Sustainability/sustainability-bg_video.webp";

import SustainUseImage from "../../src/assets/images/Sustainability/sustainabilty-use.webp";
import SustainCircularImage from "../../src/assets/images/Sustainability/sustainabilty-circular.webp";

import IconCosts from "../../src/assets/images/Sustainability/icon-costs.svg";
import IconCarb from "../../src/assets/images/Sustainability/icon-carb.svg";
import IconFlex from "../../src/assets/images/Sustainability/icon-flex.svg";
import IconReuse from "../../src/assets/images/Sustainability/icon-reuse.svg";

const ImageWithTextVariables = {
  title: "Deja tu huella",
  highlightedText: "huella",
  subtitle: "sostenibilidad a través del pago por uso y la economía circular",
  buttonText: "Descubre más",
  href: "#section1",
  image: ImageWithTextImage,
  alt: "Sostenibilidad elalquilador",
};

function Sustainability() {
  return (
    <>
      <ImageWithText variables={ImageWithTextVariables} />
      {/* to be converted to reusable componenet */}
      <section id="section1" className="">
        <div className="container m-auto text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
          <h2 className="pb-6 h1 lowercase">
            <span className="text-primary">Sostenibilidad</span> a través del
            pago por uso <br />y la{" "}
            <span className="text-primary">economía circular </span>
          </h2>
          <p className="pb-12 lg:pb-20 body leading-5 text-dark md:px-20 lg:px-64">
            En un mundo que cada vez es más consciente de la importancia de
            cuidar el medio ambiente, la sostenibilidad se ha convertido en un
            factor clave en la toma de decisiones en todos los sectores de la
            industria. El alquiler de maquinaria y herramientas ha emergido como
            una solución sostenible que combina eficiencia con responsabilidad
            ambiental.
          </p>
          {/* <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="w-full h-44 md:h-80">
              <img
                src={SustainabilityIntro1}
                className="w-full h-full object-cover rounded-3xl bg-white"
                alt="Cuidamos el medio ambiente"
              />
            </div>
            <div className="w-full h-44 md:h-80">
              <img
                src={SustainabilityIntro2}
                className="w-full h-full object-cover rounded-3xl bg-white"
                alt="Sostenibilidad"
              />
            </div>
            <div className="w-full h-44 md:h-80">
              <img
                src={SustainabilityIntro3}
                className="w-full h-full object-cover rounded-3xl bg-white"
                alt="Alquiler de maquinaria y herramientas"
              />
            </div>
            <div className="w-full h-44 md:h-80">
              <img
                src={SustainabilityIntro4}
                className="w-full h-full object-cover rounded-3xl bg-white"
                alt="Medioambiente"
              />
            </div>
          </div> */}
        </div>
      </section>
      {/* to be converted to reusable componenet + fix useEffect */}
      <SustainabilitySection />
      <section className="relative lg:hidden h-screen w-screen">
        <img
          src={backgroundImage}
          className="h-full w-full object-cover"
          alt="Nuestro compromiso con la sociedad, pensar en verde"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 headline text-white px-2 text-center">
          <img src={logoSource} className="pb-12" alt="logo elalquilador" />
          <h3>Nuestro compromiso:</h3>
          <h3>Pensar en verde</h3>
        </div>
      </section>
      <section id="">
        <div className="container m-auto text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
          {/* Sustain 1 */}
          <div className="w-full md:flex items-center gap-12 text-center md:text-start">
            <div className="h-full w-full md:w-5/12">
              <h2 className="pt-9 headline text-primary lowercase">
                Pago por uso
              </h2>
              <h3 className="py-4 h3 text-dark lowercase">
                Redefiniendo la sostenibilidad
              </h3>
              <p className="pt-2 body text-dark leading-5">
                Una de las tendencias más significativas en el alquiler de
                maquinaria y herramientas es el concepto del pago por uso. En
                lugar de comprar equipos costosos que a menudo quedan inactivos,
                las empresas pueden optar por alquilarlos solo cuando los
                necesitan. Esto no solo ahorra dinero, sino que también reduce
                significativamente la huella de carbono. Cada vez que se alquila
                una máquina en lugar de comprarla se evita la fabricación de una
                nueva unidad, lo que reduce el consumo de recursos naturales y
                la generación de residuos.
              </p>
            </div>
            <img
              src={SustainUseImage}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain order-last"
              alt="Nuevas formas de trabajar"
            />
          </div>
          {/* Sustain 2 */}
          <div className="w-full md:flex items-center gap-12 text-center md:text-start">
            <div className="h-full w-full md:w-5/12 order-2 md:order-1">
              <h2 className="pt-9 headline text-primary lowercase">
                Economía circular
              </h2>
              <h3 className="py-4 h3 text-dark lowercase">
                Fomentando la reutilización y el reciclaje
              </h3>
              <p className="pt-2 body text-dark leading-5">
                El alquiler de maquinaria y herramientas se integra
                perfectamente en la economía circular, un modelo en el que los
                productos y recursos se reutilizan y reciclan en lugar de
                desecharse. Cuando una máquina alcanza el final de su vida útil,
                las empresas de alquiler pueden garantizar su correcta
                disposición y reciclaje, evitando la acumulación de residuos en
                vertederos y promoviendo la recuperación de materiales valiosos.
              </p>
            </div>
            <img
              src={SustainCircularImage}
              className="mt-4 md:mt-0 rounded-3xl h-full w-full md:w-5/12 object-contain order-last md:order-first"
              alt="Nuevas formas de trabajar"
            />
          </div>
        </div>
      </section>
      <section id="" className="bg-white mb-12">
        <div className="container m-auto text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
          <h2 className="pb-6 h1 lowercase">
            <span className="text-primary"> Ventajas </span>
            de la sostenibilidad en el alquiler <br /> de maquinaria y
            herramientas
          </h2>
          <p className="pb-12 lg:pb-20 body leading-5 text-dark md:px-20 lg:px-64">
            El alquiler de maquinaria y herramientas no solo es una solución
            eficiente desde el punto de vista económico, sino que también es una
            elección sostenible que contribuye a la protección del medio
            ambiente y al fomento de la economía circular. Las empresas, al
            abrazar el pago por uso y la reutilización, pueden desempeñar un
            papel crucial en la construcción de un futuro más verde y
            sostenible.
          </p>
          {/* Step 1 */}
          <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-24">
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconCosts}
                  className="w-32 h-auto object-cover"
                  alt="Reducción de costos"
                />
              </div>
              <p className="h4 text-center">Reducción de costos</p>
            </div>
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconCarb}
                  className="w-32 h-auto object-cover"
                  alt="Reducción de la huella de carbono"
                />
              </div>
              <p className="h4 text-center">
                Reducción de la huella de carbono
              </p>
            </div>
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconFlex}
                  className="w-32 h-auto object-cover"
                  alt="Flexibilidad y adaptabilidad"
                />
              </div>
              <p className="h4 text-center">Flexibilidad y adaptabilidad</p>
            </div>
            <div className="w-full h-auto space-y-12 flex flex-col justify-center">
              <div className="flex justify-center">
                <img
                  src={IconReuse}
                  className="w-32 h-auto object-cover"
                  alt="Reutilización y reciclaje"
                />
              </div>
              <p className="h4 text-center">Reutilización y reciclaje</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const SustainabilitySection = () => {
  const expandableDivRef = useRef(null);
  const textBlockRef = useRef(null);

  useEffect(() => {
    // Initialize observer inside useEffect to ensure it's created with the current refs
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              textBlockRef.current.style.display = "block"; // Show the text block
            }, 200);
          } else {
            setTimeout(() => {
              textBlockRef.current.style.display = "none"; // Hide the text block
            }, 1000);
          }
        });
      },
      { threshold: 0.1 }
    );

    // Start observing
    if (expandableDivRef.current) {
      observer.observe(expandableDivRef.current);
    }

    // Cleanup function to unobserve
    return () => {
      if (expandableDivRef.current) {
        observer.unobserve(expandableDivRef.current);
      }
    };
  }, []);

  return (
    <section className="hidden lg:block h-screen w-screen m-auto overflow-hidden">
      <div className="fs-video-container relative w-full h-full flex justify-center items-center text-center">
        <div
          id="expandableDiv"
          ref={expandableDivRef}
          className="relative expand-on-scroll bg-primary h-[70vh] w-[80vw] m-auto rounded-3xl"
        >
          <video
            autoPlay
            loop
            muted
            className="video w-full h-full object-cover overflow-hidden rounded-3xl"
            alt="Nuestro compromiso con la sociedad, pensar en verde"
          >
            <source src={videoSource} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </div>

        <div
          id="textBlock"
          ref={textBlockRef}
          className="absolute top-0 left-0 w-full h-full"
          style={{ display: "none" }}
        >
          <div className="h-full flex justify-center text-center items-center">
            <div className="text-video headline text-white lowercase">
              <img
                src={logoSource}
                className="pb-12 m-auto"
                alt="logo elalquilador"
              />
              <h3>Nuestro compromiso:</h3>
              <h3>Pensar en verde</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sustainability;
