import Button from "../ui/Button";
import { HighlightedTitle } from "../ui/HighlightedTitle";
import Image from "../ui/Image";

function TextAndImage({ variables }) {
  const { title, highlightedText, content, buttonText, href, image, alt } =
    variables;
  return (
    <section className="bg-white">
      <div className="container m-auto md:text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
        <h1 className="h1 pb-9 lowercase">
          <HighlightedTitle title={title} highlight={highlightedText} />
        </h1>
        <div className="body text-dark leading-5 px-0 md:px-12 lg:px-24">
          {content.map((item, index) => (
            <h4 className="pb-4" key={index}>
              {item.item}
            </h4>
          ))}
        </div>
        <div className="py-6 w-full flex justify-center items-center">
          <Image
            src={image}
            alt={alt}
            customClass="h-32 lg:h-56 w-full object-contain"
          />
        </div>
        <div className="flex justify-center pt-2 lg:pt-9">
          <Button href={href} type="primaryWhite">
            {buttonText}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default TextAndImage;
