import { useContext } from "react";
import { PostsContext } from "../helpers/PostsContext";
import BlogCard from "../components/BlogCard";

const PostsList = () => {
  const {
    posts,
    loading,
    error,
    currentPage,
    totalPages,
    from,
    to,
    total,
    setCurrentPage,
  } = useContext(PostsContext);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="container m-auto grid grid-cols-1 gap-6 px-4 py-12 md:grid-cols-2 lg:grid-cols-3">
        {posts && posts.length > 0
          ? posts.map((post) => {
              return <BlogCard key={post.id} post={post} />;
            })
          : !loading && <p>No se encontraron posts</p>}
      </div>
      {posts && posts.length > 0 && (
        <div className="w-full">
          <p className="flex items-center justify-center gap-2 border-t border-gray pt-12">
            Mostrando{" "}
            <span className="font-bold text-primary">{to - from + 1}</span> de{" "}
            <span className="font-bold text-primary">{total}</span> artículos
          </p>
          <div className="flex items-center gap-1 justify-center mt-4">
            <button
              className={`bg-transparent rounded-full h-9 w-9 flex justify-center items-center text-darkgray body font-bold hover:bg-white hover:text-primary transition-all ease-in-out duration-150 ${
                currentPage === 1 ? "cursor-not-allowed" : ""
              }`}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke=""
                width="24"
                height="24"
              >
                <path
                  fill="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </button>
            {[...Array(totalPages)].map((_, i) => {
              if (
                i === 0 ||
                i === currentPage - 1 ||
                i === totalPages - 1 ||
                (i >= currentPage - 2 && i <= currentPage + 1) ||
                (i === currentPage - 3 && currentPage > 3) ||
                (i === currentPage + 1 && currentPage < totalPages - 2)
              ) {
                return (
                  <button
                    key={i}
                    className={`bg-transparent rounded-full h-9 w-9 flex justify-center items-center text-${
                      i === currentPage - 1 ? "primary" : "darkgray"
                    } body font-bold hover:bg-white hover:text-primary transition-all ease-in-out duration-150`}
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                );
              } else if (
                (i === currentPage - 5 && currentPage > 4) ||
                (i === currentPage + 3 && currentPage < totalPages - 3)
              ) {
                return <span key={`ellipsis-${i}`}>...</span>;
              }
              return null;
            })}
            <button
              className={`bg-transparent rounded-full h-9 w-9 flex justify-center items-center text-darkgray body font-bold hover:bg-white hover:text-primary transition-all ease-in-out duration-150 ${
                currentPage === totalPages ? "cursor-not-allowed" : ""
              }`}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke=""
                width="24"
                height="24"
              >
                <path
                  fill="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PostsList;
