import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { formatCurrency } from "../../utils/formatters";
import { DatePicker } from "./DatePicker";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import { ReactComponent as DeliveryOptions } from "../../assets/icons/DeliveryOptions.svg";
import { ReactComponent as Locations } from "../../assets/icons/Locations.svg";
import { ReactComponent as LocationsZip } from "../../assets/icons/LocationsZip.svg";

const ProductDetails = ({
  product,
  loading,
  selectedOffer,
  handleSelectOffer,
  showMoreOffers,
  toggleShowMoreOffers,
  handleIncrement,
  handleDecrement,
  quantity,
  quantityLimitMessage,
  totalDays,
  minPeriodString,
  formatDisabledDates,
  holidays,
  handleDateChange,
  resetDatePicker,
  shippingSelected,
  setShippingOptions,
  shippingOptions,
  opciones,
  options,
  selectedOptions,
  handleShippingOption,
  zip,
  handleZipChange,
  handleFetchShippingInfo,
  amount,
  selectedZip,
  selectedTown,
  // isOptionDisabled,
  selectedShippingOptions,
  resetShippingOptions,
}) => {
  const ref = useRef(null);
  const [noDelivery, setNoDelivery] = useState(null);
  const [onlyDelivery, setOnlyDelivery] = useState(null);
  const [shippingMessage, setShippingMessage] = useState(formatCurrency(0));
  const [errorShipping, setErrorShipping] = useState(null);
  const [errorZip, setErrorZip] = useState(null);
  const [shippingText, setShippingText] = useState('');
  const [hideInputCp, setHideInputCp] = useState(false);
  const [zipValue, setZipValue] = useState('');

  useEffect(() => {
    if (selectedOffer) {
      const { no_delivery, only_delivery } = selectedOffer.junior;
      setNoDelivery(no_delivery);
      setOnlyDelivery(only_delivery);
    }
  }, [selectedOffer]);

  useEffect(() => {
    if(selectedOptions[0] && !selectedOptions[1]) {
      setErrorShipping(true)
    }
    else if(selectedOptions[1] && !selectedOptions[0]) {
      setErrorShipping(true)
    }
    else {
      setErrorShipping(false)
    }
  }, [selectedOptions])

  useEffect(() => {
    if(selectedOptions[0] && selectedOptions[1]){
      if(selectedOptions[0][1] === 0 && selectedOptions[1][1] === 0){
        setHideInputCp(true)
      }
      else{
        setHideInputCp(false)
      }
    }
  }, [selectedOptions])
  
  


  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShippingOptions(false);
    }
  };

  function handleZipDetails(event){
    const inputValue = event.target.value;
    if(zipValue.length < 5 || inputValue.length < 5){
      if(/^\d*$/.test(inputValue)){
        setZipValue(inputValue)
      }
      else{
        setZipValue('')
        setErrorZip(true)
      }
    }
    
  }

  useEffect(() => {
    if(zipValue.length > 4){
      handleZipChange(zipValue)
      setErrorZip(false)
    }
    else if(zipValue > 0 && zipValue < 5){
      setErrorZip(true)
    }
  }, [zipValue])


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFetchShippingInfoWrapper = async () => {
    await handleFetchShippingInfo();
  };

  useEffect(() => {
    setShippingMessage(formatCurrency(amount));
  }, [amount])

  useEffect(() => {
    let actualText = ''
    if(selectedOptions[0]){
       actualText += selectedOptions[0][0]; 
    }
    if(selectedOptions[1]){
        actualText += ' - ' + selectedOptions[1][0];
    }
    setShippingText(actualText);
}, [selectedOptions]);



  return (
    <>
      <div className="w-full pb-4 h4 text-primary lowercase">
        {loading ? (
          <Skeleton />
        ) : (
          <span>
            {product.use_type === "machine" && "Maquinaria"}
            {product.use_type === "tool" && "Herramienta"}
            {product.use_type === "auxiliaries" && "Medios auxiliares"}
          </span>
        )}
      </div>
      <h1 className="w-full mb-2 text-black h3 lowercase">
        {loading ? <Skeleton /> : product.short_name}
      </h1>
      <div className="w-full mt-4 mb-2 font-medium body text-dark">
        {loading ? (
          <Skeleton count={2} />
        ) : (
          <p className="pb-4">{product.description}</p>
        )}
        <a
          href="#description-full"
          className="font-bold underline transition-all duration-150 ease-in-out text-darkgray hover:text-dark"
        >
          Más información
        </a>
        .
      </div>

      <div className="w-full mt-8 mb-2 text-left text-black h4 lowercase">Precio</div>
      {product.products_offers_published && product.products_offers_published.length > 0 ? (
        product.products_offers_published.map((offer, index) => (
          <div key={index} className="pb-2">
            <div className="w-full gap-4 px-4 py-4 mt-2 mb-2 bg-white rounded-xl md:flex md:py-4">
              <div className="items-center justify-center md:w-1/3 md::flex md:border-r md:border-light">
                <div className="flex gap-2 py-2 lg:px-4 md:gap-4 md:flex-col">
                  <div
                    className={`text-left w-full `}>
                    <div className="pb-4">
                      {offer === selectedOffer ? (
                          product.products_offers_published.length > 1 && (
                            <div className="flex items-center justify-center w-full gap-2 px-4 py-2 font-bold text-center text-white rounded-full bg-primary">Seleccionado
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <path d="M20 6L9 17l-5-5"/>
                                </svg>
                              </span>
                            </div>
                          )
                      ) : (
                        <button
                          onClick={() => handleSelectOffer(offer)}
                          className="w-full px-4 py-2 font-bold text-center transition-all duration-500 ease-in-out bg-white border rounded-full text-primary border-primary hover:bg-primary hover:text-white">
                          {offer === selectedOffer ? "Seleccionado" : "Seleccionar"}
                        </button>
                      )}
                    </div>
                    <div className="flex items-end gap-1 pt-2 ">
                      <span className="font-bold text-primary h2">
                        {formatCurrency(offer.days)}
                      </span>
                      <span className="font-bold text-darkgray body">/ día</span>
                    </div>
                    <span className="text-sm font-bold text-dark">
                      {offer.junior.zip} - {offer.junior.town}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full gap-8 md:w-2/3">
                
                <div className="flex flex-col w-full gap-4 pt-2 md:w-2/3 md:gap-3">
                  <div className="flex items-center justify-center gap-2 py-3 font-bold text-white uppercase rounded-full px-9 w-fit md:w-fit bg-accent caption md:px-9">
                    Fianza:
                    {loading ? (
                      <Skeleton width={40} />
                    ) : (
                      <span>{formatCurrency(offer.bail_days)}</span>
                    )}
                  </div>
                  <div className="flex w-full">
                    <div className="flex items-center justify-start w-full lg:w-1/2">
                      <div className="flex flex-col gap-2 md:p-4">
                        <div className="flex items-center gap-2 caption text-dark">
                          <div>
                            <Warning />
                          </div>
                          <span>IVA no incluído</span>
                        </div>
                        <div className="flex items-center gap-2 caption text-dark">
                          <div>
                            <Warning />
                          </div>
                          <span>Consumibles no incluídos</span>
                        </div>
                        <p className="flex items-center gap-2 caption text-dark">
                          <Warning />
                          <span>Transporte no incluído</span>
                        </p>

                      </div>
                    </div>
                    <div className="flex items-center justify-start w-full lg:w-1/2">
                      <div className="flex flex-col gap-2 md:p-4">
                        {offer.waste_management > 0 && (
                          <div className="flex items-center gap-2 caption text-dark">
                            <div>
                              <Warning />
                            </div>
                            <span>Gestión de residuos no incluído</span>
                          </div>
                        )}
                        {offer.assembly_and_disassembly > 0 && (
                          <p className="flex items-center gap-2 caption text-dark">
                            <Warning />
                            <span>Montaje no incluído</span>
                          </p>
                        )}
                        {offer.day_insurance > 0 && (
                          <p className="flex items-center gap-2 caption text-dark">
                            <Warning />
                            <span>Seguro no incluído</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="pt-2">
                  <a href="" className="underline">Comprar</a>
                </div> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <span className="font-bold text-red-500">No disponible</span>
      )}

      <div id="shop-offer" className="py-4">
        <div className="items-center w-full gap-6 mb-2 md:flex">
          <div className="text-black h4 lowercase">Configura tu pedido</div>
          <div className="flex gap-2 mt-2 md:mt-0">
            <div className="flex items-center gap-1 italic font-bold caption text-primary">
              <Check />
              Stock disponible
            </div>
          </div>
        </div>
        <div className="w-full gap-4 mt-2 md:flex">
          <div className="flex-col w-full gap-2 px-4 py-6 mb-2 space-y-6 text-left bg-white md:w-7/12 rounded-xl md:flex md:space-y-0">
            <div className="w-full gap-2 space-y-2 md:space-y-0">
              <DatePicker
                disabledDates={formatDisabledDates(holidays)}
                onDatesChange={handleDateChange}
                minPeriod={selectedOffer ? selectedOffer.min_period : null}
                periodType={selectedOffer ? selectedOffer.period_type : null}
                reset={resetDatePicker} />
            </div>
            <div className="w-full gap-2 space-y-2 md:flex md:space-y-0"></div>
            <div className="flex items-center gap-2 pt-4 border-t lg:pt-2 border-light">
              {minPeriodString !== "No hay disponible" && (
                <div className="w-full md:w-1/2">
                  <p className="px-2 pb-2 text-darkgray caption">
                    Mínimo periodo de alquiler:<span> {minPeriodString}</span>
                  </p>
                  <p className="px-2 font-bold text-dark body">
                    Total días:
                    <span className="text-primary">
                      {" "}
                      {totalDays > 0 ? totalDays - 1 : 0} día
                      {totalDays - 1 !== 1 ? "s" : ""}
                    </span>
                  </p>
                </div>
              )}
              <div className="w-1/2">
                <p className="pb-1 pl-6 caption text-darkgray">Unidades</p>
                <div className="flex items-center justify-center w-full px-6 py-2 rounded-full bg-light">
                  <button
                    className="w-8 h-8 transition-all duration-150 ease-in-out rounded-full text-darkgray h3 hover:bg-white hover:text-dark"
                    onClick={handleDecrement}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    className="w-20 font-bold text-center bg-transparent outline-none h4 text-primary"
                    value={quantity}
                    readOnly
                  />
                  <button
                    className="w-8 h-8 transition-all duration-150 ease-in-out rounded-full text-darkgray h3 hover:bg-white hover:text-dark"
                    onClick={handleIncrement}
                  >
                    +
                  </button>
                </div>
                {quantityLimitMessage && (
                  <p className="mt-2 text-center text-red-500 caption">
                    {quantityLimitMessage}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex-col w-full gap-1 px-4 py-6 mb-2 text-left bg-white md:w-5/12 rounded-xl lg:flex">
              <>
                <div className="flex flex-col mb-4">
                  <div ref={ref} className="relative flex items-center gap-3 px-6 py-3 rounded-full bg-light">
                    <DeliveryOptions />
                    <input
                      readOnly
                      className="w-full py-1 font-bold text-black bg-transparent border-none appearance-none caption focus:outline-none placeholder-darkgray placeholder:font-bold focus:placeholder-gray"
                      id="nombre"
                      type="text"
                      placeholder="¿Te lo enviamos o lo recoges?"
                      value={shippingText}
                      onClick={() => setShippingOptions(!shippingOptions)}
                    />
                    {shippingOptions && (
                      <div className="absolute left-0 right-0 z-50 flex flex-col w-full p-2 mt-2 shadow bg-light top-14 h-fit rounded-xl">
                        <div className="flex my-2">
                          <div className="flex w-1/2 flex-col justify-start items-center">
                            <span className="text-base font-bold text-primary">Entrega</span>
                            {
                              options[0].map((opcion, index) => (
                                <div
                                  key={index}
                                  className={`py-2 text-sm text-center font-bold leading-tight flex gap-2 justify-center cursor-pointer ${selectedOptions[0] && selectedOptions[0][1] === index
                                    ? "hover:bg-white hover:text-primary rounded-full ease-in-out transition-all duration-500"
                                    : "opacity-50 text-gray-500 cursor-not-allowed rounded-xl"
                                    }`}
                                  onClick={() => handleShippingOption(opcion, {0: [opcion, index]})}
                                >
                                  {selectedShippingOptions.includes(opcion) && (<Check />)}{opcion}
                                </div>
                              ))
                            }
                          </div>
                          <div className="flex w-1/2 flex-col justify-start items-center">
                            <span className="w-1/2 text-base font-bold text-center text-primary">Devolución</span>
                            {
                              options[1].map((opcion, index) => (
                                <div
                                  key={index}
                                  className={`py-2 text-sm text-center font-bold leading-tight flex gap-2 justify-center cursor-pointer ${selectedOptions[1] && selectedOptions[1][1] === index
                                    ? "hover:bg-white hover:text-primary rounded-full ease-in-out transition-all duration-500"
                                    : "opacity-50 text-gray-500 cursor-not-allowed rounded-xl"
                                    }`}
                                  onClick={() => handleShippingOption(opcion, {1: [opcion, index]})}
                                >
                                  {selectedShippingOptions.includes(opcion) && (<Check />)}{opcion}
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        {/* <div className="">
                          {opciones.map((opcion, index) => (
                            <div
                              key={index}
                              className={`py-2 text-base font-bold leading-tight flex gap-2 justify-center text-center cursor-pointer ${isOptionDisabled(opcion)
                                ? "opacity-50 text-gray-500 cursor-not-allowed rounded-xl"
                                : "hover:bg-white hover:text-primary rounded-full ease-in-out transition-all duration-500"
                                } ${selectedShippingOptions.includes(opcion) ? "text-primary font-bold " : ""}`}
                              onClick={() => !isOptionDisabled(opcion) && handleShippingOption(opcion)}
                            >
                              {selectedShippingOptions.includes(opcion) && (<Check />)}{opcion}
                            </div>
                          ))}
                        </div> */}
                      </div>
                    )}
                    {selectedShippingOptions.length > 0 && (
                      <button className="absolute px-2 py-1 rounded-lg cursor-pointer bg-light right-2 hover:bg-gray-300" onClick={resetShippingOptions}>
                        &times;
                      </button>
                    )}
                  </div>
                  {
                    errorShipping &&
                    <span className="text-red-900 text-xs">Tienes que seleccionar una opción de entrega y devolución</span>
                  }
                </div>

                <div className={`flex items-center gap-3 px-6 py-3 rounded-full bg-light ${hideInputCp ? 'hidden' : ''}`}>
                  <LocationsZip />
                  <input
                    className={`w-full py-1 font-bold text-black bg-transparent border-none appearance-none focus:outline-none placeholder-darkgray caption placeholder:font-bold focus:placeholder-gray`}
                    id="zip"
                    value={zipValue}
                    onChange={handleZipDetails}
                    type="text"
                    placeholder="C.Postal entrega / recogida"
                  />

                </div>
                {
                  errorZip &&
                  <span className="text-red-900 text-xs">Tienes que introducir un código postal correcto</span>
                }
                <div className="justify-end w-full pt-2 pb-4 text-right lg:pb-0">
                  {/* <button
                    className="pt-2 underline text-primary"
                    onClick={handleFetchShippingInfoWrapper}
                  >
                    Calcular Envío
                  </button> */}
                </div>
              </>
            <div className="flex items-center gap-2 pt-3 border-t border-light">
              <div className="flex flex-col w-full">
                <p className="text-sm font-bold">Gastos de envío y recogida estimados:</p>
                {shippingMessage === "Consultar más adelante" ? (
                  <span className="text-sm text-black">
                    {shippingMessage}
                  </span>
                ) : (
                  <span className="text-xl font-bold text-primary ">
                    {shippingMessage}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
