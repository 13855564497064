import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Hour } from "../../assets/icons/Hour.svg";
import { useError } from '../../helpers/ErrorContext';

export const DatePicker = ({
  onDatesChange,
  disabledDates,
  minPeriod,
  periodType,
  reset,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const { setError } = useError();

  useEffect(() => {
    if (reset) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [reset]);

  const periodTypes = {
    1: "hora",
    2: "días",
    3: "semana",
    4: "mes",
    5: "semestre",
    6: "año",
  };

  const getDaysFromPeriod = (minPeriod, periodType) => {
    switch (periodType) {
      case 1:
        return minPeriod / 24;
      case 2:
        return minPeriod;
      case 3:
        return minPeriod * 7;
      case 4:
        return minPeriod * 30;
      case 5:
        return minPeriod * 180;
      case 6:
        return minPeriod * 365;
      default:
        return 0;
    }
  };

  const isValidDateRange = (startDate, endDate, minPeriod, periodType) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = (end - start) / (1000 * 60 * 60 * 24);

    const minDays = getDaysFromPeriod(minPeriod, periodType);

    return timeDiff >= minDays;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleStartDateChange = (date) => {
    const newStartDate = date[0];

    if (endDate) {
      const isValid = isValidDateRange(newStartDate, endDate, minPeriod, periodType);

      if (isValid) {
        setStartDate(newStartDate);
        onDatesChange({ startDate: formatDate(newStartDate), endDate: formatDate(endDate) });
      } else {
        setError(new Error(
          `El período seleccionado no cumple con el período mínimo de alquiler de ${minPeriod} ${periodTypes[periodType]}(s).`
        ));
      }
    } else {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (date) => {
    const newEndDate = date[0];

    if (startDate) {
      const isValid = isValidDateRange(startDate, newEndDate, minPeriod, periodType);

      if (isValid) {
        setEndDate(newEndDate);
        onDatesChange({ startDate: formatDate(startDate), endDate: formatDate(newEndDate) });
      } else {
        setError(new Error(
          `El período seleccionado no cumple con el período mínimo de alquiler de ${minPeriod} ${periodTypes[periodType]}(s).`
        ));
      }
    } else {
      setEndDate(newEndDate);
    }
  };

  const getWeekendDates = () => {
    const start = new Date();
    const end = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
    const weekendDates = [];

    while (start <= end) {
      if ((start.getDay() === 0 || start.getDay() === 6) && start.toDateString() !== new Date().toDateString()) {
        weekendDates.push(new Date(start));
      }
      start.setDate(start.getDate() + 1);
    }

    return weekendDates;
  };

  const weekendDates = getWeekendDates();

  const formatDisabledDatesForFlatpickr = (dates) => {
    return dates.map((date) => ({
      from: date.startDate,
      to: date.endDate
    }));
  };

  const allDisabledDates = [
    ...formatDisabledDatesForFlatpickr(disabledDates),
    ...weekendDates
  ];

  return (
    <>
      <div className="items-center w-full gap-2 space-y-4 md:space-y-0 md:flex">
        <div className="relative w-full px-1 py-3 rounded-full gap- md:w-1/2 focus-within:border-primary focus-within:border bg-light">
          <Calendar className="absolute left-4" />
          <Flatpickr
            options={{
              dateFormat: "d-m-Y",
              minDate: "today",
              locale: Spanish,
              disable: allDisabledDates,
            }}
            value={startDate}
            onChange={handleStartDateChange}
            className="w-full pl-12 font-bold text-black bg-transparent border-none appearance-none caption focus:outline-none placeholder-darkgray placeholder:font-bold focus:placeholder-gray"
            placeholder="Fecha de inicio"
          />
        </div>
        <div className="relative w-full gap-3 px-1 py-3 rounded-full md:w-1/2 focus-within:border-primary focus-within:border bg-light">
          <Calendar className="absolute left-4" />
          <Flatpickr
            options={{
              dateFormat: "d-m-Y",
              minDate: startDate || "today",
              locale: Spanish,
              disable: allDisabledDates,
            }}
            value={endDate}
            onChange={handleEndDateChange}
            className="pl-12 font-bold text-black bg-transparent border-none appearance-none full caption focus:outline-none placeholder-darkgray placeholder:font-bold focus:placeholder-gray"
            placeholder="Fecha de fin"
          />
        </div>
      </div>
      <div className="items-center w-full gap-2 pt-4 space-y-4 md:space-y-0 md:flex">
        <div className="relative w-full gap-3 px-1 py-3 rounded-full md:w-1/2 focus-within:border-primary focus-within:border bg-light">
          <Hour className="absolute left-4" />
          <Flatpickr
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              defaultHour: '9',
              defaultMinute: '00',
              time_24hr: true,
            }}
            value={startTime}
            className="w-full pl-12 font-bold text-black bg-transparent border-none appearance-none caption focus:outline-none placeholder-darkgray placeholder:font-bold focus:placeholder-gray min-h-[20px]"
            placeholder="Hora de inicio"
          />
        </div>
        <div className="relative w-full gap-3 px-1 py-3 rounded-full md:w-1/2 focus-within:border-primary focus-within:border bg-light ">
          <Hour className="absolute left-4" />
          <Flatpickr
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              defaultHour: '18',
              defaultMinute: '00',
              time_24hr: true,
            }}
            value={endTime}
            className="pl-12 font-bold text-black bg-transparent border-none appearance-none full caption focus:outline-none placeholder-darkgray placeholder:font-bold focus:placeholder-gray min-h-[20px]"
            placeholder="Hora de fin"
          />
        </div>
      </div>
    </>
  );
};
