import React from "react";
import Image from "../../components/ui/Image";
import Button from "../ui/Button";
import { HighlightedTitle } from "../ui/HighlightedTitle";

function ImageWithText({ variables }) {
  const {
    title,
    highlightedText,
    highlightClass,
    subtitle,
    subtitle_2,
    buttonText,
    href,
    image,
    alt,
    h1AdditionalClasses,
  } = variables;

  return (
    <section className="w-full h-[90vh] flex items-center justify-center p-4 md:p-12 lg:pb-24">
      <div className="relative bg-dark w-full h-full rounded-3xl bg-dark overflow-hidden">
        <Image
          src={image}
          alt={alt}
          customClass="w-full h-full object-cover object-bottom"
        />
        <div className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center space-y-4">
          <h1
            className={`headline lowercase text-white px-4 md:px-12 lg:px-64 ${h1AdditionalClasses}`}
          >
            <HighlightedTitle
              title={title}
              highlight={highlightedText}
              highlightClass={highlightClass}
            />
          </h1>
          <h2 className="h4 text-white px-6 md:px-44 lg:px-64 lowercase">{subtitle}</h2>
          {subtitle_2 && (
            <h2 className="h4 text-white px-6 md:px-44 lg:px-64 lowercase">
              {subtitle_2}
            </h2>
          )}
          <div className="flex justify-center pt-2 lg:pt-9">
            <Button href={href} type="primary" classButton='lowercase'>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImageWithText;
