import React from 'react';
import FilterInputRadio from './FilterInputRadio';
import FilterInputRange from './FilterInputRange';
import FilterInputText from './FilterInputText';
import FilterInputSelect from './FilterInputSelect';
import FilterInputArrayRange from './FilterInputArrayRange';
import { useState, useEffect } from 'react';
import FilterInputBoolean from './FilterInputBoolean';
import FilterInputList from './FilterInputList';
import { set } from 'date-fns';

function FilterItem({filter, filtersContent, fetchedFilters, whereTopBar}) {
  const [isSelected, setIsSelected] = useState('')
  const [arrayInputIndex, setArrayInputIndex] = useState(0)
  const [filterType, setFilterType] = useState(filter[1][0])
  const [whereFilterOpen, setWhereFilterOpen] = useState(false)
  useEffect(() => {
    if (filter[0] === 'array') {
      setArrayInputIndex(prevIndex => prevIndex + 1);
    }
  }, [filter]);
  
  const handleFilterClick = (value) => {
    setIsSelected(value);
  }
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if(filterType === 'array_range' && whereTopBar ||
      fetchedFilters.zip_distance &&
      filterType === 'array_range'
    ){
      setIsOpen(true)
    }
  }, [whereTopBar, fetchedFilters])

  return (
    <div id={filter[0]} className="border-b border-black border-opacity-20 py-2 relative overflow-hidden">
      <input type="checkbox" id="item1" className="hidden" />
      <label htmlFor="item1" className="cursor-pointer accordion-label block my-2" onClick={toggleAccordion}>
        <div className="flex items-center justify-between">
          <h4 className="font-bold text-black select-none lowercase">
            {filter[1][2]}<span className="body font-bold pl-2 text-primary">{isSelected}</span>
          </h4>
          {/* Open */}
          <div className={isOpen ? "open-icon stroke-black" : "hidden close-icon stroke-black rotate-180"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="" width="24" height="24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
          {/* Close */}
          <div className={!isOpen ? "open-icon stroke-black" : "hidden close-icon stroke-black rotate-180"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="" width="24" height="24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </div>
        </div>
      </label>
      {/* Filter Content*/}
      <div className={`accordion-content overflow-hidden transition-max-height duration-300 ${isOpen ? 'max-h-full' : 'max-h-0'}  text-start`}>

            <div className="space-y-2 pb-4">
            {
              filterType === 'text' ? <FilterInputText filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters}/> :
              filterType === 'radio' ? <FilterInputRadio filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} /> :
              filterType === 'range' ? <FilterInputRange filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} /> :
              filterType === 'array' ? <FilterInputSelect filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} /> :
              filterType === 'array_range' ? <FilterInputArrayRange filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} /> :
              filterType === 'check' ? <FilterInputBoolean filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} /> :
              filterType === 'list' ? <FilterInputList filter={filter} filtersContent={filtersContent} fetchedFilters={fetchedFilters} /> :
              console.log('Filter type not found', filterType)
            }
            </div>
      </div>
    </div>
  );
}

export default FilterItem;
