import { HighlightedTitle } from "../ui/HighlightedTitle"

function Video({variables, videoSrc}) {
  const { title, highlightedText} = variables;
  return (
    <section className="bg-white px-4 lg:px-20 py-14 md:py-24">
      <div className="container m-auto text-center lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
        <h2 className="h1 lowercase">
          <HighlightedTitle title={title} highlight={highlightedText} />
        </h2>
      </div>
      <div className="w-full lg:w-[1120px] h-full lg:h-[630px] m-auto">
        <div className="w-full bg-white h-full rounded-3xl hover:scale-105 transition-all ease-in-out duration-700">
          <video autoplay loop muted controls className="w-full h-full object-cover rounded-3xl" alt="Tutorial uso elalquilador.com">
            <source src={videoSrc}  type="video/mp4"/>
            Tu navegador no soporta el elemento de video.
          </video>
        </div>
      </div>
    </section>
  )
}

export default Video
