import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/AboutUs/hero-about_us.webp";
import GridCircle from "../components/sections/GridCircle";
import GridCircleImage1 from "../../src/assets/images/AboutUs/about_us-intro_1.webp";
import GridCircleImage2 from "../../src/assets/images/AboutUs/about_us-intro_2.webp";
import GridCircleImage3 from "../../src/assets/images/AboutUs/about_us-intro_3.webp";
import GridCircleImage4 from "../../src/assets/images/AboutUs/about_us-intro_4.webp";
import TextAndImageImg from "../../src/assets/images/AboutUs/about_us-eco.webp";
import OurValues from "../components/sections/OurValues";
import TextAndImage from "../components/sections/TextAndImage";
import BrandsGrid from "../components/sections/BrandsGrid";

const ImageWithTextVariables = {
  title: "Un servicio innovador para tu empresa",
  highlightedText: "para tu empresa",

  subtitle: "",
  buttonText: "Conócenos",
  href: "#section1",
  image: ImageWithTextImage,
  alt: "Sobre elalquilador",
};

const GridCircleVariables = {
  h1: "Revolucionamos el sector del alquiler de herramientas y maquinaria",
  h2: "",
  h2HighlightedText: "herramientas y maquinaria",
  highlightClass: "text-primary block",
  h3: "¡Encuentra el mejor servicio de alquiler de maquinaria y herramientas profesionales!",
  circleItems: [
    {
      image: GridCircleImage1,
      alt: "Alquiler de herramientas y maquinaria online",
      content:
        "Con sólo unos clics compara precios, disponibilidad y características",
    },
    {
      image: GridCircleImage2,
      alt: "Alquilar herramientas y maquinaria en España",
      content:
        "Un catálogo online con los proveedores de las principales empresas de alquiler de España",
    },
    {
      image: GridCircleImage3,
      alt: "Ahorrar dinero en tu empresa de construcción",
      content: "Ahorra tiempo y dinero",
    },
    {
      image: GridCircleImage4,
      alt: "Portal de alquiler de maquinaria profesional",
      content:
        "Únete a nuestra comunidad y simplifica el proceso del alquiler de maquinaria",
    },
  ],
};

const TextAndImageVariables = {
  title: "Eco sostenibilidad",
  highlightedText: "Eco",
  content: [
    {
      item: "Desde elalquilador tenemos presente construir un mundo mejor. Por ello tenemos muy interiorizados estos conceptos:",
    },
    {
      item: "El pago por uso implica tener el acceso a productos o servicios en lugar de comprarlos, fomentando la eficiencia y reduciendo la producción para darle una vida más larga a un producto.",
    },
    {
      item: "El pago por uso implica tener el acceso a productos o servicios en lugar de comprarlos, fomentando la eficiencia y reduciendo la producción para darle una vida más larga a un producto.",
    },
    {
      item: "Con esto pretendemos cubrir el objetivo de maximizar el uso de recursos a través de acciones encaminadas a compartir productos a través del alquiler y, así, minimizar el consumo de materiales vírgenes y reducir el impacto ambiental.",
    },
  ],
  buttonText: "Saber más",
  href: "/sostenibilidad",
  image: TextAndImageImg,
  alt: "Alquiler de maquinaria sostenible y ecológico",
};

function AboutUs() {
  return (
    <>
      <ImageWithText variables={ImageWithTextVariables} />
      <GridCircle variables={GridCircleVariables} />
      <OurValues />
      <TextAndImage variables={TextAndImageVariables} />
      <BrandsGrid />
    </>
  );
}

export default AboutUs;
