import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NoImageBlog from "../assets/images/Posts/no-image-blog.webp";
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

function BlogCard({ post }) {
  const url_images = IMAGE_BASE_URL + "/";
  const location = useLocation();

  const getTypeFromLocation = (locationPath) => {
    if (locationPath.includes("/blog")) {
      return 'blog';
    } else if (locationPath.includes("/guia")) {
      return 'guia';
    }
  };
  const type = getTypeFromLocation(location.pathname);

  const createMarkup = (html) => {
    return { __html: html };
  };

  console.log(post);

  return (
    <div className="relative h-fit lg:h-[30rem] w-full rounded-3xl border border-zinc-800 bg-white">
      {/* <Link to={`/${type}/${post.slug}`}> */}
      <a href={`/${type}/${post.slug}`}>
        <div className="h-3/6 rounded-t-xl bg-green-50">
          <img
            className="object-cover w-full h-full rounded-t-xl"
            src={
              post.media && post.media[0] && post.media[0].path
                ? url_images + post.media[0].path
                : NoImageBlog
            }
            alt={post.title}
          />
        </div>
        <div className="shadow-md h-3/6 rounded-b-xl">
          <div className="flex items-center w-full px-4 pt-4">
            <div className="flex px-2 py-1 text-sm text-left text-white lowercase border-2 rounded-full border-primary bg-primary">
              {post.category.description}
            </div>
          </div>
          <div className="p-4 h-fit pb-9">
            <h1 className="font-bold text-left h4 text-dark">
              {post.title}
            </h1>
            <h2 className="pt-2 text-left text-white line-clamp-3">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  post.content.slice(0, 100) + "..."
                )}
              />
            </h2>
          </div>
          <div className="absolute bottom-0 flex justify-between w-full gap-4 p-4 text-xs bg-white text-dark rounded-xl">
            <p className="text-[12px] text-[#B8B8B8]"> {new Date(post.publication_date).toLocaleDateString()}</p>
            <div className="flex items-center justify-end gap-2">
              <p className="text-sm font-bold transition-all duration-300 text-primary easy-in-out hover:underline">
                leer más
              </p>
              <p className="text-[12px] text-[#B8B8B8]">{post.read_time < 1 ? '' : `(${post.read_time} min)`}</p>
            </div>
          </div>
        </div>
      </a>
      {/* </Link> */}
    </div>
  );
}

export default BlogCard;
