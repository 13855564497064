import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

export default function CarrouselSection({
  id,
  slides,
  slidesPerView,
  showPagination = true,
  showArrows = true,
  ArrowsCenter = false,
  class_mobile = "w-96",
  desktopViews = 6,
}) {
  const swiperRef = useRef(null);
  const testViews = 6;
  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const prevButtonId = `prevBtn-${id}`;
  const nextButtonId = `nextBtn-${id}`;

  const arrowButtonBaseClasses = "flex justify-center items-center h-12 w-12 border-2 border-primary rounded-full stroke-dark hover:bg-primary hover:stroke-white transition-all ease-in-out duration-300";
  const arrowButtonCenterClasses = "absolute top-1/2 transform -translate-y-1/2";

  return (
    <>
      {showArrows && (
        <div className={`flex pt-6 lg:pt-0 gap-4   ${ArrowsCenter ? 'justify-center' : 'justify-center md:justify-end'} mb-12 ${ArrowsCenter ? '' : 'md:mr-20'}`}>
          <button
            id={prevButtonId}
            className={`${arrowButtonBaseClasses} ${ArrowsCenter ? `${arrowButtonCenterClasses} left-2 z-50` : ''}`}
            onClick={goToPrevSlide}
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="rotate-180"
            >
              <path
                d="M13.2739 1L19.0012 6.72727L13.2739 12.4545"
                stroke=""
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.9988 7H0.998779"
                stroke=""
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button
            id={nextButtonId}
            className={`${arrowButtonBaseClasses} ${ArrowsCenter ? `${arrowButtonCenterClasses} right-2 z-50` : ''}`}
            onClick={goToNextSlide}
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="rotate-360"
            >
              <path
                d="M13.2739 1L19.0012 6.72727L13.2739 12.4545"
                stroke=""
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.9988 7H0.998779"
                stroke=""
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
      <Swiper
        cssMode={true}
        slidesPerView={slidesPerView}
        spaceBetween={20}
        navigation={{
          prevEl: `#${prevButtonId}`,
          nextEl: `#${nextButtonId}`,
        }}
        pagination={showPagination ? { type: "progressbar" } : false}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="flex items-center justify-center gap-6 px-4 pt-12 lg:p-12"
        breakpoints={{
          // when window width is >= 320px (mobile)
          320: {
            slidesPerView: 1,
          },
          // when window width is >= 768px (tablet)
          768: {
            slidesPerView: 3,
          },
          // when window width is >= 1024px (desktop)
          1024: {
            slidesPerView: desktopViews,
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className={class_mobile}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
