import React, { useState, useRef, useEffect } from "react";
import mainLogo from "../assets/logos/main-logo.svg";
import CarrouselSection from "../components/CarrouselSection";
import { useAuth } from "../hooks/AuthProvider";
import { useCartDrawer } from '../helpers/CartDrawerContext';
import { is, DataEncoder } from '../utils/common';
import {
    Drawer,
    Button,
} from "@material-tailwind/react";
import { ReactComponent as Menu } from "../assets/icons/Menu.svg";
import { ReactComponent as Close } from "../assets/icons/close.png";
import { ReactComponent as DropdownArrow } from "../assets/icons/DropdownArrow.svg";
import { ReactComponent as Cart } from "../assets/icons/Cart.svg";
import { ReactComponent as IconUser } from "../assets/icons/IconUser.svg";
import { ReactComponent as FavoritesIcon } from "../assets/icons/FavoritesIcon.svg";
import logoSubvencion from "../assets/images/logoEuropa.jpg";


const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

const NavbarSticky = () => {
    const { token, user, loading } = useAuth();
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const handleMouseEnter = () => {
        setShowMenu(true);
    };
    const handleMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setShowMenu(false);
        }, 200);

        if (menuRef.current) {
            menuRef.current.onmouseenter = () => clearTimeout(timeoutId);
        }
    };
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const [categories, setCategories] = useState([]);
    const handleModalMouseEnter = () => {
        setShowModal(true);
    };
    const handleModalMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setShowModal(false);
        }, 200);

        if (modalRef.current) {
            modalRef.current.onmouseenter = () => clearTimeout(timeoutId);
        }
    };
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const encoder = new DataEncoder();
    const arrayFilters = {
        filters: {
            home_menu: 1
        },
        with: [
            'image_menu'
        ]
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const url = `${API_BASE_URL}/menu-elements`;
                const response = await fetch(encodeURI(url));

                if (!response.ok) {
                    throw new Error('Error al cargar los productos');
                }
                const responseData = await response.json();
                // const sortedData = Object.values(responseData.data).sort((a, b) => a.order_view - b.order_view);
                setCategories(Object.values(responseData));
                // setCategories(Object.values(responseData.data))
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchCategories();
    }, []);

    const slides = categories.map((category, index) => (
        <a href={`/alquiler/categoria/${category.slug}`} key={index} className="flex-shrink-0">
            <div key={index} className="flex-col justify-center flex-shrink-0">
                <div className="flex items-center justify-center rounded-full w-32 h-32 bg-[#F4F4F4] overflow-hidden">
                    <img src={`${IMAGE_BASE_URL}/${category.image_menu ? category.image_menu.path : null}`}
                        className="object-cover transition-all duration-300 ease-in-out brandcircle hover:scale-105"
                        alt={`alquiler de ${category.description}`} />
                </div>
                <p className="w-32 mx-auto mt-2 text-sm text-center text-black button lowercase">{category.description}</p>
            </div>
        </a>
    ));

    const { toggleDrawer } = useCartDrawer();

    const [openLeft, setOpenLeft] = useState(false);
    const openDrawerLeft = () => setOpenLeft(true);
    const closeDrawerLeft = () => setOpenLeft(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

    if (loading) {
        return null;
    }

    return (
        <nav className="sticky top-0 bg-white w-screen px-4 lg:px-12 py-0 lg:py-5 flex items-center gap-4 z-[900]">
            {/* Left Content */}
            <Button onClick={openDrawerLeft} className="block p-2 bg-transparent sm:hidden text-primary shadow-transparent">
                <Menu className="w-[26px]" />
            </Button>
            <Drawer placement="left" open={openLeft} onClose={closeDrawerLeft} className="h-full p-4 overflow-y-auto" overlayProps={{
                className: 'fixed p-4 w-full  overflow-y-auto'
            }} size={600}>
                <div className="flex justify-between items-center fixed bg-white top-0 w-full pr-6">
                    <a href="/">
                        <img src={mainLogo} className="h-6" alt="elalquilador logo" />
                    </a>
                    <Button onClick={closeDrawerLeft} className="block p-2 bg-transparent sm:hidden text-primary shadow-transparent">
                        <Menu className="w-[26px]" />
                    </Button>
                </div>
                <ul className="flex flex-col gap-6 pt-16 text-left">

                    <li className="">
                        <a href="/alquiler"
                            className="w-full text-black transition-all duration-150 ease-in-out h3 hover:text-primary">
                            alquiler
                        </a>
                    </li>
                    <li className="">
                        <button
                            onClick={toggleAccordion}
                            className="flex items-center w-full text-left text-black transition-all duration-150 ease-in-out h3 hover:text-primary"
                        >
                            categorías
                            <DropdownArrow className="pl-5" />
                        </button>
                        {/* <div className="grid grid-cols-2 pt-6 justify-items-center gap-4    ">
                            <div className="w-32 h-32 rounded-full bg-light flex justify-center items-center ">
                               img cat
                            </div>
                            <div className="w-32 h-32 rounded-full bg-light flex justify-center items-center ">
                            img cat
                            </div>                
                        </div> */}
                        {isAccordionOpen && (
                            <ul className="flex flex-col gap-2 mt-2 ml-4">
                                {categories.map((category, index) => (
                                    <li key={index}>
                                        <a href={`/alquiler?categories_ids=${category.id}`} className="text-black transition-all duration-150 ease-in-out nav hover:text-primary">
                                            {category.description}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                    <li>
                        <a href="/como-funcionamos"
                            className="text-black transition-all duration-150 ease-in-out h3 hover:text-primary">
                            cómo funcionamos
                        </a>
                    </li>
                    <li>
                        <a href="/proveedores"
                            className="text-black transition-all duration-150 ease-in-out h3 hover:text-primary">
                            házte proveedor
                        </a>
                    </li>
                </ul>
                <div className="rounded-lg mt-9 ">
                {!token ? (
                        <div ref={modalRef} onMouseEnter={handleModalMouseEnter} onMouseLeave={handleModalMouseLeave}>
                            <div className="flex flex-col">
                                <a href="/register?customer_type=individual"
                                    className="flex items-center justify-center py-4 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                                    <p>soy particular</p>
                                </a>
                                <a href="/register?customer_type=bussines"
                                    className="flex items-center justify-center py-4 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                                    <p>soy autónomo</p>
                                </a>
                                <a href="/register?customer_type=company"
                                    className="flex items-center justify-center py-4 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                                    <p>soy empresa</p>
                                </a>
                                <a href="/login"
                                    className="flex items-center justify-center py-4 mt-2 text-white transition-all duration-150 ease-in-out border-2 rounded-full bg-primary button px-14 border-primary hover:bg-transparent hover:text-primary">
                                    <p>inicia sesión</p>
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col md:flex-row items-start">
                            <a href="/area-cliente" className="flex items-center justify-center gap-2 font-light text-black transition-all duration-150 ease-in-out lg:flex nav hover:text-primary stroke-black hover:stroke-primary">
                                <IconUser />
                                <p className="sm:block">{user.name || 'user'}</p>
                            </a>
                            <a href="/area-cliente" className='md:hidden font-light text-black nav'>
                                <p className='pl-7'>Ver perfil</p>
                            </a>
                        </div>
                    )}
                </div>
                

            </Drawer>

            {/* Logo */}
            <div className="flex justify-center flex-1 w-full lg:justify-start">
                <a href="/">
                    <img src={mainLogo} className="h-6" alt="elalquilador logo" />
                </a>
            </div>
            {/* Center Content */}
            <div className="hidden sm:flex sm:justify-center">
                <ul className="flex items-center justify-center gap-9">
                    <li>
                        <a href="/alquiler"
                            className="text-black transition-all duration-150 ease-in-out nav hover:text-primary">
                            alquiler
                        </a>
                    </li>
                    <li>
                        <a href="#" className="text-black transition-all duration-150 ease-in-out nav hover:text-primary"
                            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            categorías
                            {showMenu && (
                                <div ref={menuRef} className="absolute left-0 right-0 pt-8 pb-8 bg-white rounded-lg shadow-lg"
                                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <CarrouselSection id="4" slides={slides} slidesPerView={8} showPagination={false}
                                        showArrows={true} ArrowsCenter={true} desktopViews={8}/>
                                </div>
                            )}
                        </a>
                    </li>
                    <li>
                        <a href="/como-funcionamos"
                            className="text-black transition-all duration-150 ease-in-out nav hover:text-primary">
                            cómo funcionamos
                        </a>
                    </li>
                    <li>
                        <a href="/proveedores"
                            className="text-black transition-all duration-150 ease-in-out nav hover:text-primary">
                            házte proveedor
                        </a>
                    </li>
                </ul>
            </div>
            {/* Right Content */}
            <div className="justify-end w-12 lg:flex-1">
                <ul className="flex items-center justify-end gap-6">
                    {/* User */}
                    <li>
                        <a href="/subvenciones" className="">
                            <img src={logoSubvencion} alt="" className="w-12" />
                        </a>
                    </li>
                    <li>
                        <a href={token ? "/area-cliente" : "#"}
                            className="items-center justify-center hidden gap-2 font-light text-black transition-all duration-150 ease-in-out lg:flex nav hover:text-primary stroke-black hover:stroke-primary"
                            onMouseEnter={token ? null : handleModalMouseEnter} onMouseLeave={token ? null :
                                handleModalMouseLeave}>
                            <IconUser />
                            <p className="hidden sm:block">{token ? user.name || 'user' : "inicia sesión"}</p>
                        </a>
                        {/* Modal */}
                        {showModal && (
                            <div ref={modalRef}
                                className="absolute p-2 bg-white border shadow-lg right-20 top-16 rounded-3xl border-light"
                                onMouseEnter={handleModalMouseEnter} onMouseLeave={handleModalMouseLeave}>
                                <div className="flex flex-col p-4 bg-white rounded-lg">
                                    <a href="/register?customer_type=individual"
                                        className="flex items-center justify-center py-4 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                                        <p>soy particular</p>
                                    </a>
                                    <a href="/register?customer_type=bussines"
                                        className="flex items-center justify-center py-4 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                                        <p>soy autónomo</p>
                                    </a>
                                    <a href="/register?customer_type=company"
                                        className="flex items-center justify-center py-4 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
                                        <p>soy empresa</p>
                                    </a>
                                    <a href="/login"
                                        className="flex items-center justify-center py-4 mt-2 text-white transition-all duration-150 ease-in-out border-2 rounded-full bg-primary button px-14 border-primary hover:bg-transparent hover:text-primary">
                                        <p>inicia sesión</p>
                                    </a>
                                </div>
                            </div>
                        )}
                    </li>
                    {token && (
                        <li>
                            <a href="/favoritos" className="transition-all duration-150 ease-in-out fill-black hover:fill-primary">
                                {/* Icon Favorites */}
                                <FavoritesIcon />
                            </a>
                        </li>
                    )}
                    <li>
                        <button onClick={() => toggleDrawer(true)}
                            className="transition-all duration-150 ease-in-out stroke-black hover:stroke-primary">
                            {/* Icon Cart */}
                            <Cart />
                        </button>
                    </li>
                </ul>
            </div>

        </nav>
    );
};

export default NavbarSticky;
