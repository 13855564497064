import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

const ProductImages = ({ images, url_images, setThumbsSwiper, thumbsSwiper, handleImageClick }) => (
  <>
    <Swiper
      style={{ "--swiper-navigation-color": "#fff", "--swiper-pagination-color": "#fff" }}
      loop={true}
      spaceBetween={10}
      navigation={true}
      thumbs={{ swiper: thumbsSwiper }}
      modules={[FreeMode, Navigation, Thumbs]}
      className="relative w-full h-[50vh] overflow-hidden bg-white mySwiper2 mb-4 md:rounded-3xl lg:h-3/4"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index} className="object-cover w-full h-full bg-white md:rounded-3xl">
          <img
            onClick={() => handleImageClick(url_images + image.path)}
            className="object-cover w-full h-full bg-white cursor-pointer md:rounded-3xl"
            src={url_images + image.path}
            alt={image.description}
          />
        </SwiperSlide>
      ))}
    </Swiper>
    <Swiper
      onSwiper={setThumbsSwiper}
      loop={true}
      spaceBetween={10}
      slidesPerView={3}
      freeMode={true}
      watchSlidesProgress={true}
      modules={[FreeMode, Navigation, Thumbs]}
      className="hidden w-[93vw] lg:w-full gap-2 mySwiper lg:flex h-28 lg:h-44"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index} className="w-full h-full rounded-xl">
          <img
            className="object-cover w-full h-full bg-white rounded-xl"
            src={url_images + image.path}
            alt={image.description}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </>
);

export default ProductImages;
