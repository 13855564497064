import React, { useState, useEffect } from 'react';
import Button from "../components/ui/Button";

const ModalInfo = ({ isOpen, onClose }) => {
	const [data, setData] = useState({
		name: '',
		email: '',
		message: ''
	});

	const isFormValid = () => {
		return data.name !== '' &&
			data.email !== '' &&
			data.message !== ''
	};

	function validateInputs(inputs) {
		let errors = {};
		if (!inputs.name.trim()) {
			errors.name = 'El nombre es obligatorio.';
		}
		if (!inputs.email) {
			errors.email = 'El email es obligatorio.';
		} else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
			errors.email = 'Email no válido.';
		}
		if (!inputs.message.trim()) {
			errors.message = 'El mensaje es obligatorio.';
		};
		return errors;
	}

	const [errors, setErrors] = useState({});
	const [isRequestSuccessful, setRequestSuccessful] = useState(false);

	useEffect(() => {
		if (isRequestSuccessful) {
			const timer = setTimeout(() => {
				onClose();
			}, 3000);
			return () => clearTimeout(timer); 
		}
	}, [isRequestSuccessful, onClose]);

	function handleSendQuestion(event) {
		event.preventDefault();
		const validationErrors = validateInputs(data);
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
		} else {
			setErrors({});
			setRequestSuccessful(true);
		}
	}

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white rounded-2xl w-full p-8 max-w-2xl">
				{isRequestSuccessful ? (
					<div className="text-center fade-in flex flex-col gap-3">
						<h2 className="text-3xl text-primary font-bold">¡Gracias!</h2>
						<p>Se ha enviado tu correo al proveedor correctamente. En breve se pondrán en contacto contigo</p>
						<p>También hemos enviado una copia a tu correo.</p>
					</div>
				) : (
					<div className="contents">
						<h2 className="text-xl font-bold mb-8">Más información sobre el producto</h2>
						<form className='flex flex-col gap-4' onSubmit={handleSendQuestion}>
							<div className="mb-4 flex flex-col">
								<label className="text-left pl-2 text-primary font-bold" htmlFor="name">
									Nombre *
								</label>
								<input
									id="name"
									type="text"
									value={data.name}
									onChange={(e) => setData({ ...data, name: e.target.value })}
									placeholder='introduzca su nombre'
									className="focus:outline-none px-4 border rounded-full h-10 border-primary" />
								{errors.name && <p className="text-xs mt-1 text-red-500 text-right w-full">{errors.name}</p>}
							</div>
							<div className="mb-4 flex flex-col">
								<label className="text-left pl-2 text-primary font-bold" htmlFor="email">
									Correo *
								</label>
								<input
									id="email"
									type="text"
									value={data.email}
									onChange={(e) => setData({ ...data, email: e.target.value })}
									placeholder='introduzca su correo electrónico'
									className="focus:outline-none px-4 border rounded-full h-10 border-primary" />
								{errors.email && <p className="text-xs mt-1 text-red-500 text-right w-full">{errors.email}</p>}
							</div>
							<div className="mb-4 flex flex-col">
								<label className="text-left pl-2 text-primary font-bold" htmlFor="message">
									Mensaje *
								</label>
								<textarea
									id="message"
									value={data.message}
									onChange={(e) => setData({ ...data, message: e.target.value })}
									placeholder='introduzca su consulta'
									rows="4"
									className="focus:outline-none resize-none px-4 py-2 border rounded-xl border-primary" />
								{errors.message && <p className="text-xs mt-1 text-red-500 text-right w-full">{errors.message}</p>}
							</div>
							<div className="flex gap-3 justify-end">
								<Button
									type="primary"
									className="border-primary"
									onClick={onClose}>
									Cancelar
								</Button>
								<Button
									disabled={!isFormValid()}
									className="{py-2}"
									type="primary">
									Enviar
								</Button>
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

export default ModalInfo;
