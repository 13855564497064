import React, { useEffect, useState } from "react";
import CarrouselSection from "../components/CarrouselSection";
import { HighlightedTitle } from "./ui/HighlightedTitle";
import { is, DataEncoder } from "../utils/common";



export function CarrouselCategories({ title, highlightedText }) {

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;
  const [categories, setCategories] = useState([]);
  const encoder = new DataEncoder();
  const arrayFilters = {
    filters: {
      home_carrousel: 1
    },
    with: [
      'image_carrousel'
    ]
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const url = `${API_BASE_URL}/categories?${encoder.encode(arrayFilters)}`;
        const response = await fetch(encodeURI(url));
        if (!response.ok) {
          throw new Error('Error al cargar los productos');
        }
        const responseData = await response.json();
        setCategories(Object.values(responseData.data));
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchCategories();
  }, []);


  const slides = categories.map((category, index) => (
    <a
      href={`/alquiler/categoria/${category.slug}`}
      key={index}
      className="flex-shrink-0"
    >
      <div className="flex flex-col justify-center space-y-6 text-center">
        <img
          src={`${IMAGE_BASE_URL}/${category.image_carrousel ? category.image_carrousel.path : null}`}
          className="object-cover h-32 transition-all duration-300 ease-in-out bg-white w-44 rounded-3xl hover:scale-105"
          alt={`alquiler de ${category.description}`}
        />
        <p className="flex justify-center mx-auto text-base text-center w-44 lowercase">{category.description}</p>
      </div>
    </a>
  ));

  return (
    <section className="space-y-2 text-center text-black py-14 md:py-24 h2">
      <div>
        <div className="px-4 lg:px-20">
          <h2>
            <HighlightedTitle title={title} highlight={highlightedText} />
          </h2>
        </div>
        <CarrouselSection id="1" slides={slides} slidesPerView={6} class_mobile="categories_carrousel_mobile" />
        <div className="flex justify-center pt-9">
          <a href="/alquiler">
            <button className="flex items-center justify-center py-6 mt-2 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
              <p>ver todas</p>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}
