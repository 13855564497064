import Image from "../ui/Image";

function StepItem({ step, index }) {
  const { title, content, image, alt, reverse } = step;

  return (
    <div className="w-full md:flex items-center gap-12 text-center md:text-start">
      <div className={`h-full w-full md:w-5/12 ${reverse ? 'order-2 md:order-1' : ''}`}>
        <h2 className="pt-9 headline text-black">
          {index + 1}.<span className="text-primary lowercase inline-flex"> {title} </span> 
        </h2>
        <p className="pt-2 body font-bold text-black leading-5">
          {content}
        </p>
      </div>
      <Image src={image} alt={alt} customClass={`h-full w-full md:w-7/12 object-contain ${reverse ? 'order-last md:order-first' : 'md:order-2'}`}/>
    </div>
  );
}

export default StepItem
