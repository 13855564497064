import React from "react";

function Cookies() {
	return (
		<section className="text-left px-4 lg:px-20 py-14 md:py-24 lg:flex-col flex  gap-20">
			<h1 className="pb-2 text-4xl text-left font-bold text-black md:text-4xl">
				Política de cookies
			</h1>
			<div className="grid grid-cols-1 gap-0 rounded-3xl border border-primary  px-4 py-2 md:grid-cols-2">
				<div className="flex items-center rounded-3xl border border-white border-opacity-10 px-4 py-6 md:pl-12">
					<input id="link-checkbox1" type="checkbox" x-model="check_1" className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />
					<label for="link-checkbox1" className="ml-4 cursor-pointer font-bold text-black">
						Cookies técnicas y estrictamente necesarias
					</label>
				</div>
				<div className="flex items-center rounded-3xl border border-white border-opacity-10 px-4 py-6 md:pl-12">
					<input id="link-checkbox2" type="checkbox" x-model="check_2" className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />
					<label for="link-checkbox2" className="ml-4 cursor-pointer font-bold text-black">
						Mejora de experiencia y personalización
					</label>
				</div>
				<div className="flex items-center rounded-3xl border border-white border-opacity-10 px-4 py-6 md:pl-12">
					<input id="link-checkbox3" type="checkbox" x-model="check_3" className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />
					<label for="link-checkbox3" className="ml-4 cursor-pointer font-bold text-black">
						Medición y análisis
					</label>
				</div>
				<div className="flex items-center rounded-3xl border border-white border-opacity-10 px-4 py-6 md:pl-12">
					<input id="link-checkbox4" type="checkbox" x-model="check_4" className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />
					<label for="link-checkbox4" className="ml-4 cursor-pointer font-bold text-black">
						Segmentación y publicidad
					</label>
				</div>
				<div className="flex w-full items-center justify-end md:col-span-2">

					<button type="button" className="mr-4 cursor-pointer rounded-xl bg-primary px-4 py-3 text-sm font-bold text-black transition-all duration-300 ease-in-out hover:bg-opacity-80">
						Personalizar cookies
					</button>


					<button type="button" className="rounded-xl bg-red-400 px-4 py-3 text-sm font-bold text-black transition-all duration-300 ease-in-out hover:bg-opacity-80">
						Eliminar cookies
					</button>

				</div>
			</div >
			<div className="space-y-2 py-2 flex flex-col  text-left">
				<p className="text-sm text-black text-opacity-60">
					Las cookies son un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
				</p>
				<p className="text-sm text-black text-opacity-60">
					La presente Política de Cookies tiene por finalidad informarle de manera clara y precisa sobre las Cookies que se utilizan en elgestionador.digitalpromo.es La información recabada a través de las Cookies podría incluir la fecha y hora de visitas al Sitio Web, las páginas visionadas, el tiempo que ha estado en nuestro Sitio Web y los sitios visitados justo antes y después del mismo.
				</p>
				<p className="text-sm font-bold text-black">
					Los tipos de cookies que utiliza esta página web son las siguientes:
				</p>
				<p className="text-sm text-black text-opacity-60">
					Cookies técnicas son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.
				</p>
				<p className="text-sm font-bold text-black">
					Nuestro Sitio Web utiliza las Cookies que se describen a continuación:
				</p>
				<p className="text-sm text-black text-opacity-60">
					Si quisiese retirar en cualquier momento su consentimiento relacionado con la presente Política de Cookies, deberá eliminar las Cookies almacenadas en su equipo (ordenador o dispositivo móvil) a través de los ajustes y configuraciones de su navegador de Internet.
				</p>
				<p className="text-sm text-black text-opacity-60">
					Salvo que haya ajustado la configuración de su navegador, nuestro sistema creará Cookies en cuanto visite nuestro Sitio Web.
				</p>
				<p className="text-sm text-black text-opacity-60">
					Para obtener y analizar la información de la navegación, utilizamos Google Analytics, la cual ha sido desarrollada por Google, que nos presta el servicio de análisis de la audiencia de nuestra página. Esta empresa puede utilizar estos datos para mejorar sus propios servicios y para ofrecer servicios a otras empresas. Puede conocer esos otros usos desde los enlaces indicados.
				</p>
				<p className="text-sm text-black text-opacity-60">
					Esta herramienta no obtiene datos de los nombres o apellidos de los usuarios ni de la dirección postal desde donde se conectan. La información que obtiene está relacionada por ejemplo con el número de páginas vistas, el idioma, red social en la que se publican nuestras noticias, la ciudad a la que está asignada la dirección IP desde la que acceden los usuarios, el número de usuarios que nos visitan, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador que usan, el operador o tipo de terminal desde el que se realiza la visita. Esta información la utilizamos para mejorar nuestra página, detectar nuevas necesidades y valorar las mejoras a introducir con la finalidad de prestar un mejor servicio a los usuarios que nos visitan.
				</p>
				<p className="text-sm font-bold text-black">
					¿Cómo deshabilitar las Cookies en los navegadores más usados?
				</p>
				<p className="text-sm text-black text-opacity-60">
					Debe configurar su navegador de Internet para que las borre del disco duro de su ordenador, las bloquee o le avise en caso de instalación de las mismas. Para continuar sin cambios en la configuración de las Cookies, simplemente continúe en la página web.
				</p>
				<p className="text-sm text-black text-opacity-60">
					Firefox desde aquí:
					<a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" className="underline">Firefox</a>
				</p>
				<p className="text-sm text-black text-opacity-60">
					Chrome desde aquí:
					<a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" className="underline">Google Chrome</a>
				</p>
				<p className="text-sm text-black text-opacity-60">
					Explorer desde aquí:
					<a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" className="underline">Internet Explorer</a>
				</p>
				<p className="text-sm text-black text-opacity-60">
					Safari desde aquí:
					<a href="https://support.apple.com/es-es/105082" target="_blank" className="underline">Safari</a>
				</p>
				<p className="text-sm text-black text-opacity-60">
					Es posible que actualicemos la Política de Cookies de nuestro Sitio Web, por ello le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web. Solo el propietario de este sitio web utiliza y tiene acceso a las Cookies del mismo. Los terceros que hayan almacenado Cookies en su ordenador tendrán acceso ellos. Para cualquier duda acerca de la política de Cookies no dude en comunicarse con nosotros a través de datos@elgestionador.com
				</p>
			</div>
		</section >
	);
}

export default Cookies;
