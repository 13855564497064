import React, { createContext, useReducer, useContext, useState, useEffect } from 'react';

// Definimos el contexto
const FilterContext = createContext();

// Definimos el reducer para manejar los filtros
const filterReducer = (state, action) => {
  switch (action.type) {
    // Aquí puedes manejar diferentes tipos de acciones para actualizar los filtros
    case 'PER_PAGE':
      return { ...state, urlTestParams: { ...state.urlTestParams, per_page: action.payload } };
    case 'SORT_BY':
      return { ...state, urlTestParams: { ...state.urlTestParams, sort: action.payload } };
    case 'SET_PAGE':
      return { ...state, urlTestParams: { ...state.urlTestParams, page: action.payload } };
    case 'SET_SEARCH':
      return { ...state, urlTestParams: { ...state.urlTestParams, search: action.payload, page: 1 } };
    case 'SET_FILTERS':
      return { ...state, filtersSelected: action.payload };
    case 'UPDATE_FILTERS':
      return { ...state, urlTestParams: { ...state.urlTestParams, filters: { ...state.urlTestParams.filters, [action.payload.filterId]: action.payload.values }, page: 1 } };
    case 'RESET_FILTERS':
      return { ...state, urlTestParams: { ...state.urlTestParams, filters: {}, page: 1 } };
    case 'REMOVE_FILTER':
      const newFilters = { ...state.urlTestParams.filters };
      delete newFilters[action.payload];
      return { ...state, urlTestParams: { ...state.urlTestParams, filters: newFilters, page: 1 } };
    case 'REMOVE_SORT':
      return { ...state, urlTestParams: { ...state.urlTestParams, sort: null } };
    case 'CHANGE_PAGE':
      return { ...state, urlTestParams: { ...state.urlTestParams, page: action.payload } };
    case 'UPDATE_CATEGORIE':
      return { ...state, categorie: action.payload };
    default:
      return state;
  }
};

// Definimos el proveedor de contexto
const FilterProvider = ({ children }) => {
  const [perPage, setPerPage] = useState(21);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    with_offers: true
  });
  const initialState = {
    urlTestParams: {
      'with': [
        'image',
        'products_offers_published.junior:id,zip'
      ],
      'filters': filters,
      'page': 2,
      'per_page': perPage
    },
    categorie: 'Todos los productos',
    filtersSelected:{}
  };

  const [state, dispatch] = useReducer(filterReducer, initialState);
  const setSearchTerm = (term) => {
    setSearch(term);
    dispatch({ type: 'SET_SEARCH', payload: term });
  };

  const updateFilters = (filterId, values) => {
    dispatch({ type: 'UPDATE_FILTERS', payload: { filterId: filterId, values: [values] } });
  };

  const resetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
    window.location.reload();
  }

  const changePage = (page) => {
    dispatch({ type: 'CHANGE_PAGE', payload: page });
  }
  
  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

// Función de utilidad para acceder al contexto
const useFilterContext = () => {
  return useContext(FilterContext);
};

export { FilterProvider, useFilterContext };
