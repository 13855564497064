import React, { useState, useEffect } from 'react';
import { useFilterContext } from '../helpers/FilterContext';
import FilterItem from './FilterItem';

const Filters2 = ({filters, fetchedFilters, filtersContent, whereTopBar}) => {
  const { state, dispatch } = useFilterContext();
  return (
    <div>
      <div className="pt-4 pb-[400px]">
      {
        filters.filters && Object.entries(filters.filters).map((filter, index) => 
          filter[1][0] != 'search'
            ? <FilterItem key={index} filtersContent={filtersContent} filter={filter} fetchedFilters={fetchedFilters} whereTopBar={whereTopBar}/>
            : null
        )
      }
      </div>
    </div>
  );
};

export default Filters2;