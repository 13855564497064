import React, { useState, useEffect } from 'react';
import Menu from '../../components/private/Menu';
import ProductCardSkeleton from '../../components/ProductCardSkeleton';
import ProductCard from '../../components/ProductCard';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAuth } from '../../hooks/AuthProvider';
import { useError } from '../../helpers/ErrorContext';

function Favorites() {
  const { isAuthenticated } = useAuth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('userToken');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const urlParams = [
    ["with[0]", "favorite_products.image"],
    ["with[1]", "favorite_products.products_offers_published"],
  ];

  const paramString = urlParams
    .map(([param, value]) => `${param}=${value}`)
    .join("&");

  useEffect(() => {
    if (isAuthenticated) {
      const fetchFavorites = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/user?${paramString}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            }
          });

          const data = await response.json();
          if (!response.ok) {
              const errorMessages = Object.values(data.errors)
              .flat()
              .join(', ');
              throw new Error(errorMessages);
          }
          console.log("User data fetched:", data);
          setProducts(data.favorite_products); 
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setLoading(false);
        }
      };

      fetchFavorites();
    }
  }, [isAuthenticated, token]);

  return (
    <div className="p-[15px] md:p-[35px] flex flex-col md:flex-row gap-8 md:gap-24 min-h-[calc(100vh-135px)]">
      <Menu position="payments"/>
      <div className="w-[73%]">
        <h2 className="text-left text-[34px] text-primary font-bold mb-2">favoritos</h2>
        <div className="w-full bg-white h-full flex flex-col items-start justify-start rounded-xl p-8">
          <div className='w-full px-9'>
          <div className="flex gap-4 flex-wrap">
            {loading ? (
              <>
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
              </>
            ) : (
              products.map(product => (
                <ProductCard
                  key={product.id}
                  product={product}
                  isAuthenticated={isAuthenticated}
                  isFavorite={true} 
                />
              ))
            )}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Favorites;
