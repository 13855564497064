import React, { useState } from 'react';
import { useEvent } from '../helpers/useEvent';

function NewsletterSection() {

  const [data, setData] = useState({ email: '', });
  const [errors, setErrors] = useState({});
  const [isRequestSuccessful, setRequestSuccessful] = useState(false); // Nuevo estado
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  function validateInputs(inputs) {
    let errors = {};
    if (!inputs.email) {
      errors.email = 'La contraseña es obligatoria.';
    } else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
      errors.email = 'Email no válido.';
    }
    return errors;
  }

  function handleNewsletter(event) {
    event.preventDefault();
    const validationErrors = validateInputs(data);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      fetch(`${API_BASE_URL}/newsletter-register`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then(response => {
        return response.json().then(data => ({ status: response.status, body: data }));
      }).then(result => {
        if (result.status === 200) {
          setRequestSuccessful(true);
        } else if (result.status === 422) {
          const errors = result.body.errors || {};
          const errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key].join(', ')}`).join('\n');
          alert(`Errores de validación:\n${errorMessages}`);
        } else if (result.status === 500) {
          alert('Error en el servidor');
        } else {
          alert('Error en la petición');
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    }
  }

  const sendEvent = useEvent();

  const handleClick = (eventType) => {
    sendEvent(eventType);
  };

  return (
    <section className="bg-light px-4 py-12">
      <div className="text-left bg-white container m-auto rounded-2xl py-12 lg:py-20 px-4 lg:px-32 md:flex items-center justify-center shadow-lg shadow-zinc-100">
        {isRequestSuccessful ? (
          <div className="text-center fade-in flex flex-col gap-3">
            <h2 className="text-3xl text-primary font-bold">¡Gracias!</h2>
            <p>Se ha registrado tu correo correctamente, ya estas en lista para nuestra siguiente newsletter</p>
          </div>
        ) : (
          <div className="contents">
            <div className="space-y-2 w-full md:w-1/2">
              <h3 className="body font-bold text-dark">
                ¿Quieres recibir consejos y las últimas novedades?
              </h3>
              <h2 className="h2 text-dark">
                suscríbete a{" "}
                <span className="text-primary">nuestra newsletter</span>
              </h2>
              <p className="pt-2 text-dark text-opacity-60 body">
                Solo utilizaremos tu email para enviarte nuestras newsletters
                (ofertas, promociones, etc.). Puedes desuscribirte en cualquier
                momento utilizando el enlace que hay en la parte inferior de la
                newsletter. Más información sobre la gestión de mis datos.
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center mt-9 lg:mt-0">
              <div>
                <form onSubmit={handleNewsletter}>
                  <input
                    className="w-[80vw] m-auto text-center md:w-80 font-bold appearance-none button text-dark rounded-full px-2 py-6 border-2 border-dark focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray placeholder:text-center"
                    id="mail"
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })}
                    type="text"
                    placeholder="introduce tu e-mail" />
                  {errors.email && <p className="text-xs mt-1 text-red-500 text-right w-full">{errors.email}</p>}
                  <button onClick={(event) => handleClick('formSubmission"', event)} className="w-[80vw] md:w-80 mt-2 m-auto bg-primary button flex items-center justify-center text-white rounded-full px-2 py-6 border-2 border-primary hover:bg-transparent hover:text-primary transition-all ease-in-out duration-150">
                    <p>suscribirme</p>
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default NewsletterSection;
