import { parseContent } from "../../helpers/parseContent";
import { HighlightedTitle } from "../ui/HighlightedTitle";
import Image from "../ui/Image";

function GridCircle({ variables }) {
  const { h1, h2, h2HighlightedText, highlightClass, h3, circleItems } =
    variables;
  return (
    <section id="section1" className="">
      <div className="container m-auto text-center px-4 lg:px-20 py-14 md:py-24 text-black h2 space-y-1">
        <h1 className="h1 lowercase">
          <HighlightedTitle
            title={h1}
            highlight={h2HighlightedText}
            highlightClass={highlightClass}
          />
        </h1>
        {/* <h2 className="pb-6 h1 lowercase">
          <HighlightedTitle title={h2} highlight={h2HighlightedText} />
        </h2> */}
        <h3 className="py-4 h4 px-0 md:px-12 lg:px-44">{h3}</h3>
        <div className="body text-dark leading-5 px-0 md:px-12 lg:px-24">
          <div className="pt-9 w-full grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            {circleItems.map((circleItem, index) => (
              <GridCircleItem key={index} circleItem={circleItem} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function GridCircleItem({ circleItem }) {
  const { image, alt, content } = circleItem;

  return (
    <div className="text-center h-full">
      <Image
        src={image}
        alt={alt}
        customClass="m-auto h-44 md:h-64 w-44 md:w-64 object-cover rounded-full bg-white"
      />
      <h4 className="pt-9 h-full md:h-44 px-4">{parseContent(content)}</h4>
    </div>
  );
}

export default GridCircle;
