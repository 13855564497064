import React, { useEffect, useState } from "react";
import mainLogo from ".././assets/logos/main-logo.svg";
import addCardIcon from ".././assets/images/checkout/add-card.svg";
import paySecureIcon from ".././assets/images/checkout/pay-secure.svg";
import visaIcon from ".././assets/images/checkout/visa.svg";
import paypalIcon from ".././assets/images/checkout/paypal.svg";
import maestroIcon from ".././assets/images/checkout/maestro.svg";
import CartDrawerContent from "../components/CartDrawerContent";
import { Typography } from "@material-tailwind/react";
import { useAuth } from "../hooks/AuthProvider";
import { formatCurrency } from '../utils/formatters';
import Input from "../components/ui/Input";
import { useError } from '../helpers/ErrorContext';
import { add, set } from "date-fns";
import { is } from "../utils/common";

const Checkout = () => {
    const { setError } = useError();
    const initialAddress = {
        email: "",
        phone: "",
        name: "",
        surname: "",
        password: "",
        password_confirmation: "",
        vat: "",
        legal_form: "",
        address: "",
        town: "",
        province: "",
        zip: "",
        country_id: "212",
        customer_type: "individual",
    };
    const initialLogisticAddress = {
        label: "",
        phone: "",
        address: "",
        town: "",
        province: "",
        zip: "",
        country_id: "212",
    };
    const [cartData, setCartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { token, isAuthenticated } = useAuth();
    const [userData, setUserData] = useState(null);
    const [fiscalAddress, setFiscalAddress] = useState(null);
    const [zip, setZip] = useState("");
    const [showCustomerForm, setShowCustomerForm] = useState(false);
    const [checkSameFiscal, setCheckSameFiscal] = useState(false);
    const [customerData, setCustomerData] = useState({
        vat: "",
        legal_form: "",
        address: "",
        town: "",
        province: "",
        zip: "",
        country_id: "212",
    });
    const [formData, setFormData] = useState(initialAddress);
    const [logisticsAddress, setLogisticsAddress] = useState(initialLogisticAddress);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [checkoutReady, setCheckoutReady] = useState(true);
    const [ authToken, setAuthToken ] = useState(null);


    const [shippingNewInfo, setShippingNewInfo] = useState({
        address: "",
        province: "",
        zip: "",
        town: "",
    });

    useEffect(() => {
        if(isAuthenticated){
            if (checkSameFiscal) {
                setLogisticsAddress({
                    address: fiscalAddress.address,
                    province: fiscalAddress.province,
                    zip: fiscalAddress.zip,
                    town: fiscalAddress.town,
                    country_id: "212"
                })
            }
        }
        else{
            if (checkSameFiscal) {
                setLogisticsAddress({
                    address: formData.address,
                    province: formData.province,
                    zip: formData.zip,
                    town: formData.town,
                    country_id: "212"
                })
            }
        }
    }, [checkSameFiscal]);




    useEffect(() => {
        const fetchUserData = async () => {
            if (!isAuthenticated) return;
            try {
                const response = await fetch(
                    `${API_BASE_URL}/user?with[]=thirdparty.addresses&with[]=thirdparty.fiscal_address`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const data = await response.json();
                if (!response.ok) {
                const errorMessages = data.errors
                    ? Object.values(data.errors).flat().join(', ')
                    : 'Error al cargar los datos del usuario';
                throw new Error(errorMessages);
                }
                setUserData(data);
                setFiscalAddress(data.thirdparty ? data.thirdparty.fiscal_address : initialAddress);
                setShowCustomerForm(!data.thirdparty);
                const defaultAddress = data.thirdparty?.addresses?.find(addr => addr.default) || data.thirdparty?.addresses[0];
                if (defaultAddress?.zip) {
                    setZip(defaultAddress.zip);
                } else {
                    console.log("No default address found or address has no zip");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setError(error);
            }
        };
        fetchUserData();
    }, [isAuthenticated, token, setError]);

    const handleCustomerChange = (e) => {
        const { name, value } = e.target;
        setCustomerData(prev => ({
            ...prev,
            [name]: value,
        }));
        if (name === 'zip' && value.length >= 5) {
            setZip(value);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
        if (name === 'zip' && value.length >= 5) {
            setZip(value);
        }
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    useEffect(() => {
        let timer;
        if (logisticsAddress.zip.length >= 5 && logisticsAddress.address && logisticsAddress.province && logisticsAddress.town) {
          timer = setTimeout(() => {
            recalculateCart();
            setCheckoutReady(false);
          }, 1000);
        }
        else{
            setCheckoutReady(true);
        }
    
        // Limpiar el temporizador si logisticsAddress cambia antes de que el temporizador actual se complete
        return () => clearTimeout(timer);
      }, [logisticsAddress]);


    const handleChangeLogisticsAddress = (e) => {
        const { name, value, type, checked } = e.target;
        setLogisticsAddress({
            ...logisticsAddress,
            [name.replace('log_', '')]: type === "checkbox" ? checked : value,
        });
        if (name.replace('log_', '') === 'zip' && value.length >= 5) {
            setZip(value);
            // recalculateCart(value);
        }
    };

    const submitCustomerData = async () => {
        try {
            
            const response = await fetch(
                `${API_BASE_URL}/register-customer`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(customerData),
                }
            );
            const result = await response.json();
            if (!response.ok) {
                console.error("Failed to register customer, response:", result);
                const error = new Error("Failed to register customer");
                error.details = result.errors || result.message || "Error desconocido";
                setError(error);
                throw error;
            }
            console.log("Customer registered:", result);
        } catch (error) {
            console.error("Error registering customer:", error);
            setError(error);
        }
    };

    const handleSubmit = async (e) => {
		e.preventDefault();
	
		try {
            if(!checkSameFiscal){
                if(!formData.vat || !formData.address || !formData.province || !formData.zip || !formData.town || !formData.email || !formData.phone || !formData.name || !formData.surname || !formData.password || !formData.password_confirmation){
                    console.log(customerData);
                    throw new Error("Por favor, rellene todos los campos obligatorios");
                    return;
                }
            }
            
            if(formData.password !== formData.password_confirmation){
                throw new Error("Las contraseñas no coinciden");
                return;
            }

			if (showCustomerForm) await submitCustomerData();
	
			if (!isAuthenticated) {
                registerCustomer();
			} else {
				setAuthToken(localStorage.getItem("userToken"));
                shoppingCartFetcher(localStorage.getItem("userToken"));
			}
		} catch (error) {
			console.error("Error:", error.message);
			setError(error);
		}
	};

    const registerCustomer = async () => { 
        try {
            const responseRegister = await fetch(
                `${API_BASE_URL}/register-with-customer`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...formData,
                        ...{ logisticsAddress }
                    }),
                }
            );

            const data = await responseRegister.json();
            if (!responseRegister.ok) {
                const errorMessages = Object.values(data.errors)
                    .flat()
                    .join(' - ');
                throw new Error(errorMessages);
            }
            else{
                console.log("Customer registered:", data);
                setAuthToken(data.auth_token);
                console.log("Auth Token:", data.auth_token);
                localStorage.setItem("userToken", data.auth_token);
                shoppingCartFetcher(data.auth_token);
            }
        } catch (error) {
            console.error("Error registering customer:", error);
            setError(error);
        }
    }

    const shoppingCartFetcher = async (token) => {
        try{
            const sessionId = localStorage.getItem("session_id");
            const responsePatch = await fetch(
                `${API_BASE_URL}/shopping-cart/${sessionId}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        zip: logisticsAddress.zip,
                        address: logisticsAddress.address,
                        province: logisticsAddress.province,
                        town: logisticsAddress.town
                    })
                }
            );
            const responseData = await responsePatch.json();
            if (!responsePatch.ok) {
                const errorMessages = Object.values(responseData.errors)
                        .flat()
                        // lets make join with <br> to show errors in new line
                        .join('<br>');
                throw new Error(errorMessages);
            }
            else{
                localStorage.removeItem("session_id");
                window.location.href = "/gracias";
            }
        }
        catch(error){
            console.error("Error:", error.message);
            setError(error);
        }
    }
    
    const urlParams = [
        ["with[0]", "sales_orders.sales_orders_lines.product.image"],
        ["with[1]", "state"],
    ];

    const paramString = urlParams
        .map(([param, value]) => `${param}=${value}`)
        .join("&");

    useEffect(() => {
        const fetchCartData = async () => {
            const sessionId = localStorage.getItem("session_id");
            if (!sessionId) {
                console.error("No session ID found");
                setError("No session ID found");
                return;
            }

            const url = `${API_BASE_URL}/shopping-cart/${sessionId}?${paramString}`;
            setIsLoading(true);
            try {
                const response = await fetch(url, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                
                const data = await response.json();
                if (!response.ok) {
                    const errorMessages = Object.values(data.errors)
                            .flat()
                            .join(', ');
                    throw new Error(errorMessages);
                }
                setCartData(data);
            } catch (error) {
                console.error("Error fetching cart data:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCartData();
    }, [paramString, setError]);
        

    const recalculateCart = async (zipCode) => {
        console.log("Recalculating cart");
        const sessionId = localStorage.getItem("session_id");
        if (!sessionId) {
            console.error("No session ID found");
            setError(new Error("No session ID found"));
            return;
        }

        const url = `${API_BASE_URL}/shopping-cart/${sessionId}/recalculate`;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ 
                    zip: logisticsAddress.zip,
                    address: logisticsAddress.address,
                    province: logisticsAddress.province,
                    town: logisticsAddress.town
                }),
            });

            const updatedCartData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(updatedCartData.errors)
                        .flat()
                        .join(', ');
                throw new Error(errorMessages);
            }
            setCartData(updatedCartData);
            console.log("Updated cart data:", updatedCartData);
        } catch (error) {
            console.error("Error fetching updated cart data:", error);
            setError(error);
        }
    };

    // useEffect(() => {
    //     if (isAuthenticated && userData && userData.zip) {
    //         setZip(userData.zip);
    //         if(userData.zip.length >= 5){
    //             recalculateCart(userData.zip);
    //         }
    //     }
    // }, [isAuthenticated, userData]);


    const handleDeliveryLine = async (sessionId, lineId, options, zipCode) => {
        const url = `${API_BASE_URL}/shopping-cart/${sessionId}/offer/${lineId}`;
        const body = {
            store_pickup: options.storePickup ? "1" : "0",
            store_delivery: options.storeDelivery ? "1" : "0",
            zip: zipCode,
        };

        console.log("Sending delivery options with ZIP:", body);

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const updatedCartData = await response.json();
            if (!response.ok) {
                const errorMessages = Object.values(updatedCartData.errors)
                        .flat()
                        .join(', ');
                throw new Error(errorMessages);
            }
            setCartData(updatedCartData);
            console.log("Delivery options updated successfully. Updated data:", updatedCartData);
        } catch (error) {
            console.error('Error updating delivery options:', error);
            setError(error);
        }
    };

    const sessionId = localStorage.getItem("session_id") || "";

    return (
        <div>
            <div className="w-screen border-b border-b-zinc-200">
                <div className="container flex items-center justify-between h-24 gap-4 px-4 m-auto">
                    <a href="/">
                        <img src={mainLogo} className="w-auto h-7" alt="Main Logo" />
                    </a>
                    <a
                        href="/alquiler"
                        className="transition-all duration-300 ease-in-out body text-dark hover:text-primary">
                        Volver a alquilar
                    </a>
                </div>
            </div>
            <div className="w-screen bg-white">
                <div className="container m-auto lg:flex">
                    <div className="lg:w-[55vw] p-6 text-left">
                        <h1 className="pb-2 h2 text-dark">Checkout</h1>
                        <div>
                            <h1 className="inline-flex py-6 h3 text-dark">
                                Datos de contacto
                            </h1>
                            {isAuthenticated ? (
                                <div>
                                    {userData && (
                                        <div className="flex flex-col gap-5">
                                            <h1>¡Hola <span className="font-bold">{userData.name} {userData?.surname}!</span></h1>
                                            <div className="flex flex-col gap-2">
                                                <div className="font-bold text-primary">Dirección email</div>
                                                <p>Email: {userData.email}</p>
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <div className="font-bold text-primary">Dirección fiscal</div>
                                                <div className="flex flex-col gap-5 md:flex-row">
                                                    <Input label="Dirección" disabled={fiscalAddress.address ? false : true} id="address" name="address" required={true} placeholder="escriba la dirección" value={fiscalAddress.address} width="w-full md:w-3/5"></Input>
                                                    <Input label="Provincia" disabled={fiscalAddress.province ? false : true} id="province" name="province" required={true} placeholder="provincia" value={fiscalAddress.province} width="w-full md:w-2/5"></Input>
                                                </div>
                                                <div className="flex flex-col gap-5 md:flex-row">
                                                    <Input label="C.P" id="zip" disabled={fiscalAddress.zip ? false : true} name="zip" required={true} placeholder="c.p" value={fiscalAddress.zip} width="w-full md:w-1/5"></Input>
                                                    <Input label="Población" id="town" disabled={fiscalAddress.town ? false : true} name="town" required={true} placeholder="población" value={fiscalAddress.town} width="w-full md:w-4/5"></Input>
                                                </div>
                                            </div>
                                            <div>
                                                <a href="/perfil" className="text-primary">Editar mi dirección fiscal</a>
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <div className="font-bold text-primary">Dirección de entrega</div>
                                                <div className="flex items-center gap-3">
                                                    <input
                                                        type="checkbox"
                                                        id="sameAddress"
                                                        value={checkSameFiscal}
                                                        onChange={(e) => { setCheckSameFiscal(e.target.checked) }}
                                                        checked={checkSameFiscal == true ? true : false} />
                                                    <label htmlFor="sameAddress" className="cursor-pointer">Usar la misma dirección de facturación</label>
                                                </div>
                                                <div>
                                                    {checkSameFiscal == true ? (
                                                        <div></div>
                                                    ) : (
                                                        <div className="flex flex-col gap-3 mt-4">
                                                            <div className="flex flex-col gap-5 md:flex-row">
                                                                <Input label="Dirección" id="address" name="address" required={true} placeholder="escriba la dirección" value={logisticsAddress.address} onChange={handleChangeLogisticsAddress} width="w-full md:w-3/5"></Input>
                                                                <Input label="Provincia" id="province" name="province" required={true} placeholder="provincia" value={logisticsAddress.province} onChange={handleChangeLogisticsAddress} width="w-full md:w-2/5"></Input>
                                                            </div>
                                                            <div className="flex flex-col gap-5 md:flex-row">
                                                                <Input label="C.P" id="zip" name="zip" required={true} placeholder="c.p" value={logisticsAddress.zip} onChange={handleChangeLogisticsAddress} width="w-full md:w-1/5"></Input>
                                                                <Input label="Población" id="town" name="town" required={true} placeholder="población" value={logisticsAddress.town} onChange={handleChangeLogisticsAddress} width="w-full md:w-4/5"></Input>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        {showCustomerForm && (
                                            <form onSubmit={handleSubmit}>
                                                <div className="flex flex-col gap-5">
                                                    <div className="flex gap-5">
                                                        <Input label="CIF/NIF/NIE" id="vat" name="vat" required={true} placeholder="cif/nif/nie" value={customerData.vat} onChange={handleCustomerChange} width="w-full md:w-3/12"></Input>
                                                        <Input label="Razón social" id="legal_form" name="legal_form" required={true} placeholder="razón social" value={customerData.legal_form} onChange={handleCustomerChange} width="w-9/12"></Input>
                                                    </div>
                                                    <div className="mt-5 font-bold text-primary">Dirección fiscal</div>
                                                    <div className="flex gap-5">
                                                        <Input label="Dirección" id="address" name="address" required={true} placeholder="escriba la dirección" value={customerData.address} onChange={handleCustomerChange} width="w-3/5"></Input>
                                                        <Input label="Provincia" id="province" name="province" required={true} placeholder="provincia" value={customerData.province} onChange={handleCustomerChange} width="w-2/5"></Input>
                                                    </div>
                                                    <div className="flex gap-5">
                                                        <Input label="C.P" id="zip" name="zip" required={true} placeholder="c.p" value={customerData.zip} onChange={(e) => {
                                                            const newZip = e.target.value;
                                                            setCustomerData((prevFormData) => ({
                                                                ...prevFormData,
                                                                zip: newZip,
                                                            }));
                                                            recalculateCart(newZip);
                                                        }}
                                                            width="w-1/5">
                                                        </Input>
                                                        <Input label="Población" id="town" name="town" required={true} placeholder="población" value={customerData.town} onChange={handleCustomerChange} width="w-4/5"></Input>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="contents">
                                    <a href="/login">
                                        <h1 className="inline-flex py-6 h6 float-end text-primary">
                                            ¿Tienes una cuenta? Iniciar sesión
                                        </h1>
                                    </a>
                                    <div className="flex flex-col w-full gap-1 mb-5 md:gap-0 md:justify-between md:flex-row">
                                        <div className="flex gap-3">
                                            <input type="radio" name="customer_type" value="individual" onChange={handleChange} id="customer_type_1" checked={formData.customer_type === 'individual'} />
                                            <label className="text-lg cursor-pointer" htmlFor="customer_type_1">Soy particular</label>
                                        </div>
                                        <div className="flex gap-3">
                                            <input type="radio" name="customer_type" value="bussines" onChange={handleChange} id="customer_type_2" checked={formData.customer_type === 'bussines'} />
                                            <label className="text-lg cursor-pointer" htmlFor="customer_type_2">Soy autonómo</label>
                                        </div>
                                        <div className="flex gap-3">
                                            <input type="radio" name="customer_type" value="company" onChange={handleChange} id="customer_type_3" checked={formData.customer_type === 'company'} />
                                            <label className="text-lg cursor-pointer" htmlFor="customer_type_3">Soy empresa</label>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit} className="flex flex-col w-full gap-3">
                                        <div className="flex flex-col gap-5 md:flex-row">
                                            <Input label="Mail" id="email" name="email" required={true} placeholder="email" value={formData.email} onChange={handleChange} width="w-full md:w-8/12"></Input>
                                            <Input label="Teléfono" id="phone" name="phone" required={true} placeholder="phone" value={formData.phone} onChange={handleChange} width="w-full md:w-4/12"></Input>
                                        </div>
                                        <div className="flex flex-col gap-5 md:flex-row">
                                            <Input label="Nombre" id="name" name="name" required={true} placeholder="nombre" value={formData.name} onChange={handleChange} width="w-full md:w-2/5"></Input>
                                            <Input label="Apellidos" id="surname" name="surname" required={true} placeholder="apellidos" value={formData.surname} onChange={handleChange} width="w-full md:w-3/5"></Input>
                                        </div>
                                        <div className="flex flex-col gap-5 md:flex-row">
                                            <Input label="Contraseña" id="password" name="password" type="password" required={true} placeholder="contraseña" value={formData.password} onChange={handleChange} ></Input>
                                            <Input label="Repita contraseña" id="password_confirmation" name="password_confirmation" type="password" required={true} placeholder="confirme contraseña" value={formData.password_confirmation} onChange={handleChange}></Input>
                                        </div>
                                        <div className="flex flex-col gap-5 md:flex-row">
                                            <Input label={formData.customer_type === 'individual' ? 'DNI' : 'CIF/NIF/NIE'} id="vat" name="vat" required={true} placeholder={formData.customer_type === 'individual' ? 'DNI' : 'CIF/NIF/NIE'} value={formData.vat} onChange={handleChange} width="w-full md:w-3/12"></Input>
                                            {(formData.customer_type == "company") && (
                                                <Input label="Razón social" id="legal_form" name="legal_form" required={true} placeholder="razón social" value={formData.legal_form} onChange={handleChange} width="w-full md:w-9/12"></Input>
                                            )}
                                        </div>
                                        {/*  */}
                                    </form>
                                    <div>
                                        <div className="mt-5 font-bold text-primary">Dirección fiscal</div>
                                        <div className="flex flex-col gap-5 md:flex-row">
                                            <Input label="Dirección" id="address" name="address" required={true} placeholder="escriba la dirección" value={formData.address} onChange={handleChange} width="w-full md:w-3/5"></Input>
                                            <Input label="Provincia" id="province" name="province" required={true} placeholder="provincia" value={formData.province} onChange={handleChange} width="w-full md:w-2/5"></Input>
                                        </div>
                                        <div className="flex flex-col gap-5 md:flex-row">
                                            <Input label="C.P" id="zip" name="zip" required={true} placeholder="c.p" value={formData.zip} onChange={handleChange} width="w-full md:w-1/5"></Input>
                                            <Input label="Población" id="town" name="town" required={true} placeholder="población" value={formData.town} onChange={handleChange} width="w-full md:w-4/5"></Input>
                                        </div>
                                        <div className="mt-5 font-bold text-primary">Dirección de entrega</div>
                                        <div className="flex items-center gap-3">
                                            <input
                                                type="checkbox"
                                                id="sameAddress"
                                                value={checkSameFiscal}
                                                onChange={(e) => { setCheckSameFiscal(e.target.checked) }}
                                                checked={checkSameFiscal == true ? true : false} />
                                            <label htmlFor="sameAddress" className="cursor-pointer">Usar la misma dirección de facturación</label>
                                        </div>
                                        <div>
                                            {checkSameFiscal == true ? (
                                                <div></div>
                                            ) : (
                                                <div className="flex flex-col gap-3 mt-4">
                                                    <div className="flex flex-col gap-5 md:flex-row">
                                                        <Input label="Etiqueta para la dirección" id="log_label" name="log_label" required={true} placeholder="escriba un nombre para la dirección" value={logisticsAddress.label} onChange={handleChangeLogisticsAddress} width="w-full md:w-3/5"></Input>
                                                    </div>
                                                    <div className="flex flex-col gap-5 md:flex-row">
                                                        <Input label="Dirección" id="log_address" name="log_address" required={true} placeholder="escriba la direcciónn" value={logisticsAddress.address} onChange={handleChangeLogisticsAddress} width="w-full md:w-3/5"></Input>
                                                        <Input label="Provincia" id="log_province" name="log_province" required={true} placeholder="provincia" value={logisticsAddress.province} onChange={handleChangeLogisticsAddress} width="w-full md:w-2/5"></Input>
                                                    </div>
                                                    <div className="flex flex-col gap-5 md:flex-row">
                                                        <Input label="C.P" id="log_zip" name="log_zip" required={true} placeholder="c.p" value={logisticsAddress.zip} onChange={handleChangeLogisticsAddress} width="w-full md:w-1/5"></Input>
                                                        <Input label="Población" id="log_town" name="log_town" required={true} placeholder="población" value={logisticsAddress.town} onChange={handleChangeLogisticsAddress} width="w-full md:w-4/5"></Input>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="lg:w-[45vw] sticky top-0 h-fit p-6 lg:p-10">
                        <div className="p-2 rounded bg-zinc-50">
                            <h1 className="pb-6 text-xl">Resumen del pedido</h1>
                            {isLoading ? (
                                <Typography color="gray" className="pr-4 mb-8 font-normal">
                                    Cargando datos del carrito...
                                </Typography>
                            ) : (
                                <CartDrawerContent
                                    cartData={cartData}
                                    onDeliveryLine={handleDeliveryLine}
                                    showDeleteButton={false}
                                    showDeliveryOptions={true}
                                    zip={zip}
                                    sessionId={sessionId}
                                />
                            )}
                            {cartData && cartData.base_amount && (
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <div className="flex justify-between gap-4 px-2">
                                            <p>Productos:</p>
                                            <p className="font-bold">
                                                {formatCurrency(cartData.base_product)}
                                            </p>
                                        </div>
                                        {((cartData.base_day_insurance || 0) + (cartData.base_other_insurances || 0) > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Seguros:</p>
                                                <p className="font-bold">
                                                    {formatCurrency((cartData.base_day_insurance || 0) + (cartData.base_other_insurances || 0))}
                                                </p>
                                            </div>
                                        )}
                                        {(cartData.base_waste_management > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Gestión residuos:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_waste_management)}
                                                </p>
                                            </div>
                                        )}
                                        {(cartData.base_consumables > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Consumibles:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_consumables)}
                                                </p>
                                            </div>
                                        )}
                                        {(cartData.base_scaffolding_reviews > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Revisión andamios:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_scaffolding_reviews)}
                                                </p>
                                            </div>
                                        )}
                                        {(cartData.base_assembly_and_disassembly > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Montaje y desmontaje:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_assembly_and_disassembly)}
                                                </p>
                                            </div>
                                        )}
                                        {(cartData.base_battery_recharge > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Recarga baterias:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_battery_recharge)}
                                                </p>
                                            </div>
                                        )}
                                        {(cartData.base_shipping > 0) && (
                                            <div className="flex justify-between gap-4 px-2">
                                                <p>Transporte:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_shipping)}
                                                </p>
                                            </div>
                                        )}
                                        <div className="flex justify-between gap-4 px-2">
                                            <p>Base total:</p>
                                            <p className="font-bold">
                                                {formatCurrency(cartData.base_amount - (cartData.base_bail || 0))}
                                            </p>
                                        </div>
                                        <div className="flex justify-between gap-4 px-2">
                                            <p>Impuestos</p>
                                            <p className="font-bold">
                                                {formatCurrency(cartData.tax_amount)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-4 px-2 py-2 mt-2 bg-gray-300 border-t border-primary-200">
                                        {(cartData.base_bail > 0) && (
                                            <div className="flex justify-between w-full gap-4 ">
                                                <p>Fianzas:</p>
                                                <p className="font-bold">
                                                    {formatCurrency(cartData.base_bail)}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex justify-between gap-4 px-2 py-4 border-t border-primary-200">
                                        <p className="font-bold h4 text-primary">Total</p>
                                        <p className="font-bold h4">
                                            {formatCurrency(cartData.total_amount)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        <button
                            onClick={handleSubmit}
                            className={`flex items-center justify-center w-full p-5 mt-4 text-white transition-all duration-300 ease-in-out rounded  h4 hover:brightness-110 bg-primary }`}>
                            Confirmar pedido
                        </button>
                        <div className="pt-4 text-xs font-medium text-dark">
                            Al realizar tu pedido, aceptas la
                            <span className="text-zinc-400">Política de privacidad </span>,
                            <span className="text-zinc-400">Términos </span>y
                            <span className="text-zinc-400"> Condiciones</span>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default Checkout;
