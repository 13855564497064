import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PostsContext } from "../helpers/PostsContext";
import PostsList from "../components/PostsList";
import BlogFilters from "../components/BlogFilters";
import ImageTitle from "../assets/images/Posts/bg-blog.webp";

function Posts() {

  const location = useLocation();
  
  let title;
  if (location.pathname === '/guia') {
    title = '<span className="text-primary">guias</span>elalquilador';
  } else if (location.pathname === '/blog') {
    title = 'el<span className="text-primary">alquilablog</span>';
  } else {
    title = '<span className="text-primary">elalquilablog</span>';
  }

  const { filters, currentPage, updateFilters, setCurrentPage } =
    useContext(PostsContext);

  return (

    <>
      <section className="flex gap-5 px-4 text-left lg:px-20 py-14 md:py-24 lg:flex-col text-primary">
        <div>
          <div className="relative h-[50vh] w-full overflow-hidden rounded-xl bg-dark">
            <img
              src={ImageTitle}
              alt="elalquilador blog"
              className="object-cover w-full h-full md:object-center"
            />
            <div className="absolute w-full space-y-4 text-center transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <h1 className="px-4 text-4xl font-bold lowercase md:px-12 md:text-7xl lg:px-64" dangerouslySetInnerHTML={{ __html: title }}>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <BlogFilters initialFilters={filters} onFilterChange={updateFilters} />
      <PostsList
        filters={filters}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default Posts;
